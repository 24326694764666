export const parseQueryString = (queryString: string) => {
  if (!queryString.trim()) {
    return {};
  }

  const categories = queryString.split("::");
  const parsed: { [key: string]: string } = {};
  categories.forEach((category: string) => {
    const [key, value] = category.split(":");
    parsed[key] = value;
  });
  return parsed;
};

export const removeDuplicateSearchParams = (url: string) => {
  const [basePath, queryString] = url.split("?");

  if (!queryString) return url;

  const params = queryString.split("&");
  const queryParams = new Set([...params]);

  const updatedParams = Array.from(queryParams);
  const updatedQueryString = updatedParams.join("&");

  return `${basePath}?${updatedQueryString}`;
};

export const redirectionRoutes = [
  {
    from: "/used-vehicle-lists/Bangalore",
    to: "/Bangalore/buy-two-wheelers",
  },
  {
    from: "/used-vehicle-lists/Coimbatore",
    to: "/Coimbatore/buy-two-wheelers",
  },
  { from: "/used-vehicle-lists/Chennai", to: "/Chennai/buy-two-wheelers" },
];
