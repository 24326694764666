import { useScreenDetector } from "@/hooks/useScreenDetector";
import { ChevronLeft as LeftIcon } from "lucide-react";
import Navbar from "../Navbar";
import { cn } from "@/lib/utils";
import { ReactElement } from "react";
import Pill from "../Pill";
import { TickIconFilled } from "@/assets/Icons";

type FlowStepperProps = {
  onBackClick: () => void;
  headerContent: ReactElement;
  onPillClick: (step: { type: string; title: string }) => void;
  isStepComplete: (step: string) => boolean;
  steps: {
    type: string;
    title: string;
  }[];
  activeStep: string;
  stepTitles: {
    [key: string]: ReactElement;
  };
  renderActiveStep: () => ReactElement;
};

const FlowStepper = ({
  onBackClick,
  headerContent,
  onPillClick,
  isStepComplete,
  steps,
  activeStep,
  stepTitles,
  renderActiveStep,
}: FlowStepperProps) => {
  const { isMobile } = useScreenDetector();
  const activeStepIndex =
    steps.findIndex((step) => step.type === activeStep) + 1;

  return (
    <section className="flex flex-col bg-neutral30 h-dvh">
      {!isMobile && (
        <Navbar
          showLocation={false}
          showSearchbar={false}
          showLinks={false}
          backgroundColor="bg-white"
        />
      )}

      <div
        className={cn(
          "flex gap-2 pt-4 px-4 md:px-0 max-w-screen-md mx-auto w-full cursor-pointer",
          isMobile ? "mt-0" : "mt-20",
        )}
        onClick={onBackClick}
      >
        <LeftIcon />
        {headerContent}
      </div>

      <div className="flex overflow-x-scroll whitespace-nowrap mt-6 no-scrollbar px-4 md:px-0 max-w-screen-md mx-auto w-full">
        {steps.map((step) => {
          return (
            <Pill
              id={`step_${step.type}`}
              key={step.type}
              name={step.title}
              className={cn(
                "border shadow-sm px-4 py-2 mr-4 bg-white rounded-lg cursor-pointer",
                activeStep === step.type && "border-primaryA1",
              )}
              childClassName={cn(
                "bg-white text-base font-semibold",
                activeStep === step.type || isStepComplete(step.type)
                  ? "text-primaryA1"
                  : "text-neutral300",
              )}
              rightHalf={isStepComplete(step.type) && <TickIconFilled />}
              handleClick={() => onPillClick(step)}
            />
          );
        })}
      </div>

      <div className="border m-4 px-4 pb-4 rounded-lg shadow-sm h-full bg-white overflow-y-scroll no-scrollbar relative md:max-w-screen-md md:mx-auto md:w-full flex flex-col gap-4">
        <div className="flex justify-between items-center sticky top-0 bg-white pt-4">
          {stepTitles[activeStep]}
          <p className="text-base text-neutral-90 font-semibold leading-6">
            {activeStepIndex}/{steps.length}
          </p>
        </div>

        {renderActiveStep()}
      </div>
    </section>
  );
};

export default FlowStepper;
