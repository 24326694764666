import { toast } from "@/components/ui/use-toast";

type ShareTypes = {
  title?: string;
  text?: string;
  toastTitle?: string;
  toastVariant?: "default" | "destructive" | "success";
  vehicleName?: string;
};

export function handleShare({
  title = "DriveX",
  vehicleName,
  text = `Hey, have you seen the ${vehicleName} on DriveX?

It includes 3 free services and a 1-year DriveX warranty on purchase!

Check it out here:`,
  toastTitle = "Link copied to clipboard",
  toastVariant = "success",
}: ShareTypes) {
  const isShareAvailable = "share" in navigator;
  const shareData = {
    title,
    url: window.location.href,
    text,
  };

  if (isShareAvailable) {
    window.navigator.share(shareData);
  } else {
    navigator.clipboard.writeText(window.location.href);
    toast({
      title: toastTitle,
      variant: toastVariant,
    });
  }
}
