import { Link, useLocation } from "react-router-dom";
import { CallIcon, DriveXLogo } from "@/assets/Icons";
import { QuickLinks, SocialMediaImages } from "../../pages/Home/data";
import { Button } from "../ui/button";
import { FOOTER_CALL_FOR_SUPPORT_EVENT } from "@/utils/mixpanel/Events/footer_clicked_events";
import { track } from "@/utils/mixpanel/actions.ts";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import { getCurrentYear } from "@/utils/date.ts";
import { getPageSource } from "@/utils/mixpanel/pageSource";
import { supportContactNumber } from "@/utils/constants.ts";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import Image from "../ui/image";
import { useSelector } from "react-redux";

const Footer = () => {
  const { isMobile } = useScreenDetector();
  const pathURL = useLocation();
  const { city } = useCurrentCity();
  const pageSource = getPageSource(pathURL.pathname);
  const user = useSelector((state: any) => state.user.currentUser);

  const handleSupportClick = () => {
    track(FOOTER_CALL_FOR_SUPPORT_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  const constructUrl = (url: string) => {
    if (url === "/buy-two-wheelers") {
      return `/${city}${url}`;
    }
    return url;
  };

  return (
    <footer className="p-4 py-8 max-w-7xl mx-auto w-full">
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="lg:w-[50%] flex flex-col gap-6">
          <div className="flex justify-between items-center flex-wrap md:justify-start md:gap-20">
            <span data-test-id="drivex-logo">
              <DriveXLogo size={130} />
            </span>

            <a href={`tel:${supportContactNumber}`}>
              <Button
                onClick={handleSupportClick}
                variant="outline"
                className="flex gap-3 bg-primaryA2 border-none text-white"
              >
                <CallIcon />
                <p className="font-semibold text-sm text-white">
                  {supportContactNumber}
                </p>
              </Button>
            </a>
          </div>

          <p className="font-medium text-white md:max-w-[460px]">
            We&apos;re committed to creating a technology-driven trustable
            ecosystem to sell two-wheelers and buy top-quality refurbished
            two-wheelers while making the process quick, easy and hassle-free.
          </p>

          <div className="flex gap-6">
            {SocialMediaImages.map((data, index) => (
              <Link to={data.link} target="_blank" key={index}>
                <Image src={data.img as string} alt={data.alt} />
              </Link>
            ))}
          </div>
        </div>

        <div className="min-w-[50%] flex flex-col gap-4 md:flex-row justify-between">
          <span className="flex flex-col gap-4">
            <p className="text-white text-lg font-semibold">Quick Links</p>
            <div className="columns-2">
              {QuickLinks.map((link, index) => (
                <div key={index} className="py-1.5">
                  <Link
                    data-test-id={`footer-${link.text.replaceAll(" ", "-").toLowerCase()}`}
                    to={constructUrl(link.url as string)}
                    className="text-white text-base font-medium"
                  >
                    {link.text}
                  </Link>
                </div>
              ))}
            </div>
          </span>
          {/* Commented out as it is not required for MVP */}
          {/* <span
            className={cn(
              "flex flex-col gap-4 w-80",
              isMobile && "border-t-2 border-[#2F2F2F] mt-6 w-full",
            )}
          >
            <p
              className={cn(
                "text-white text-lg font-semibold",
                isMobile && "pt-6",
              )}
            >
              Popular Searches
            </p>
            <div className="flex gap-2 flex-wrap divide-x-2 ">
              {PopularSearches.map((link, index) => (
                <div key={index} className={`${index === 0 ? "pl-0" : "pl-2"}`}>
                  <Link
                    to={`/productlist?q=${link.text.toLowerCase()}`}
                    className="text-white text-base font-medium"
                  >
                    {link.text}
                  </Link>
                </div>
              ))}
            </div>
          </span> */}
        </div>
      </div>

      <div className="border-t-2 mt-6 border-[#2F2F2F]">
        <p className="text-base text-neutral300 text-center mb-[-30px] pt-6">
          &copy;{getCurrentYear} DriveX Mobility Pvt Ltd.{" "}
          {!isMobile && "All rights reserved."}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
