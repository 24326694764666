import {
  BikeIcon,
  WrenchIcon,
  FilterIcon,
  Heart,
  KeyIcon,
  SortIcon,
} from "@/assets/Icons";
import { IBottomNavigation } from ".";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import { getWishListedVehicle } from "@/store/services/scheduleApi";
import { setIsWishListed } from "@/store/features/wishListSlice";
import { useLocation } from "react-router-dom";
import { track } from "@/utils/mixpanel/actions";
import {
  BOTTOMNAVIGATION_BUY_CLICKED_EVENT,
  BOTTOMNAVIGATION_SELL_CLICKED_EVENT,
  BOTTOMNAVIGATION_SORT_CLICKED_EVENT,
  BOTTOMNAVIGATION_FILTER_CLICKED_EVENT,
  BOTTOMNAVIGATION_SERVICE_CLICKED_EVENT,
} from "@/utils/mixpanel/Events/bottomNavigation_clicked_events";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useScreenDetector } from "@/hooks/useScreenDetector";

export const maxCountDisplay = 10; // Variable to store the maximum count display value

export const productListingTabsData: IBottomNavigation["tabs"] = [
  {
    icon: () => <SortIcon />,
    text: "Sort",
    action: "sort",
  },
  {
    icon: () => <FilterIcon />,
    text: "Filter",
    action: "filter",
  },
];

export function HomeTabs() {
  const { wishList } = useSelector((state: any) => state.wishList);
  const { city } = useCurrentCity();
  const { isDesktop } = useScreenDetector();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state: any) => state.user.currentUser);
  const hasWishlistItems = user && wishList && wishList?.length > 0;

  useEffect(() => {
    if (location.pathname === "/wishlist" || !user) return;
    const wishListedVehicleAvailable = async () => {
      const fetchWishList = await getWishListedVehicle(`compact=true`);
      dispatch(setIsWishListed(fetchWishList));
    };

    if (!hasWishlistItems) {
      wishListedVehicleAvailable();
    }
  }, [user]);

  const focusedIconStyle = { oneStopColor: "#FFFFFF", twoStopColor: "#FFFFFF" };
  const mobileTabsOrder = ["Buy", "Service", "Sell"];

  const getWishlistAction = useCallback(() => {
    return !user ? `${location.pathname}?login=true` : "/wishlist";
  }, [user, location]);

  const getWishlistCount = useMemo(() => {
    return hasWishlistItems && wishList.length;
  }, [user, wishList]);

  const homeTabsData: IBottomNavigation["tabs"] = [
    {
      icon: (focused: boolean) => (
        <BikeIcon {...(focused && focusedIconStyle)} />
      ),
      text: "Buy",
      action: `/${city}/buy-two-wheelers`,
    },
    {
      icon: (focused: boolean) => (
        <WrenchIcon size={18} {...(focused && focusedIconStyle)} />
      ),
      text: "Service",
      action: `/service`,
    },
    {
      icon: (focused: boolean) => (
        <Heart
          id="wishlist_bottom_navigation"
          {...(focused && focusedIconStyle)}
        />
      ),
      text: "Wishlist",
      action: getWishlistAction(),
      count: getWishlistCount,
    },
    {
      icon: (focused: boolean) => (
        <KeyIcon {...(focused && focusedIconStyle)} />
      ),
      text: "Sell",
      action: "/sell-two-wheelers",
    },
  ];

  const mobileTabsData = homeTabsData.filter((data) =>
    mobileTabsOrder.includes(data.text),
  );

  return isDesktop ? { homeTabsData } : { homeTabsData: mobileTabsData };
}

const getEventName = (text: string) => {
  switch (text) {
    case "Buy":
      return BOTTOMNAVIGATION_BUY_CLICKED_EVENT;
    case "Sell":
      return BOTTOMNAVIGATION_SELL_CLICKED_EVENT;
    case "Service":
      return BOTTOMNAVIGATION_SERVICE_CLICKED_EVENT;
    case "Sort":
      return BOTTOMNAVIGATION_SORT_CLICKED_EVENT;
    case "Filter":
      return BOTTOMNAVIGATION_FILTER_CLICKED_EVENT;
    default:
      break;
  }
};

export const trackBottomNavigation = ({
  tab,
}: {
  tab: IBottomNavigation["tabs"][0];
}) => {
  track(getEventName(tab.text) as string, {
    page: "BottomNav",
  });
};
