import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import Providers from "./store/provider.jsx";
import * as Sentry from "@sentry/react";
import ErrorPage from "../src/pages/Home/Error.tsx";
import { sentryInit } from "./utils/sentry.ts";
import { Toaster } from "@/components/ui/toaster";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { HelmetProvider } from "react-helmet-async";

sentryInit();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage onFallback={true} />}>
      <Providers>
        <HelmetProvider>
          <FlagsmithProvider
            options={{
              environmentID: `${import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID}`,
              api: `${import.meta.env.VITE_FLAGSMITH_API_URL}`,
            }}
            flagsmith={flagsmith}
          >
            <App />
          </FlagsmithProvider>
        </HelmetProvider>
        <Toaster />
      </Providers>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
