import { Link } from "react-router-dom";
import { moreOptions } from "./data";

const MoreOptions = () => {
  return (
    <>
      {moreOptions.map((options, index) => (
        <Link
          data-test-id={`sidebar-${options.text.replaceAll(" ", "-").toLowerCase()}`}
          key={index}
          className="flex items-center gap-3 p-4 px-2"
          to={options.link}
        >
          {options.icon}
          <p className="font-semibold text-sm">{options.text}</p>
        </Link>
      ))}
    </>
  );
};

export default MoreOptions;
