import { useEffect, useState } from "react";

export const useScreenDetector = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;
  const isTablet = width > 768 && width < 1024;
  const isDesktop = width >= 1024;

  // Check if the screen width is large (>= 1024 and <= 1280px) ref: https://tailwindcss.com/docs/responsive-design
  const isLargeScreen = isDesktop && width <= 1280;

  return { isMobile, isTablet, isDesktop, isLargeScreen };
};
