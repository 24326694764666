import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useState } from "react";

interface IProps {
  handleChange: (value: string | number, type: string) => void;
  stores: IStore[];
  location: string;
  setOpenLocation: React.Dispatch<React.SetStateAction<any>>;
}

const ServiceCenter = ({
  handleChange,
  stores,
  location,
  setOpenLocation,
}: IProps) => {
  const [openSelect, setOpenSelect] = useState(false);
  return (
    <div className="grid w-full items-center gap-1.5 ">
      <label htmlFor="terms" className="text-sm font-semibold leading-2">
        Service Center
      </label>

      <div>
        <Select
          onOpenChange={(val) => {
            if (!location) {
              setOpenLocation(true);
              return;
            }
            setOpenSelect(val);
          }}
          open={openSelect}
          onValueChange={(value) => handleChange(parseInt(value), "store_id")}
        >
          <SelectTrigger className=" w-full text-left py-6 data-[placeholder]:text-neutral300 bg-neutral30 focus-visible:ring-0 focus-visible:ring-offset-0">
            <SelectValue placeholder="Select the nearest service center" />
          </SelectTrigger>
          <SelectContent
            className="bg-white"
            ref={(ref) => {
              if (!ref) return;
              ref.ontouchstart = (e) => e.preventDefault();
            }}
          >
            {stores?.map((store, index) => (
              <SelectItem
                key={index}
                value={store?.store_id.toString()}
                className="py-4 px-2 pl-7 focus:bg-neutral30"
              >
                <div className="text-sm mb-4">{store?.store_name}</div>
                <p className="text-neutral300 text-xs text-wrap max-w-[230px] capitalize">
                  {store?.address}
                </p>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export default ServiceCenter;
