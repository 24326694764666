import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

export interface FAQsDataType {
  content: {
    heading: string;
    description: string;
  }[];
  handleFAQClick: (heading: string) => void;
}

const FAQs = ({ content, handleFAQClick }: FAQsDataType) => {
  return (
    <section className="flex flex-col max-w-[60rem] mx-auto w-full gap-y-4">
      <p className="text-xl font-semibold">FAQs</p>
      <div className="p-1">
        <Accordion type="single" collapsible>
          <div className="flex flex-col gap-4 text-left">
            {content.map((faq, index) => (
              <div
                onClick={() => handleFAQClick(faq.heading)}
                key={index}
                className="flex flex-col gap-4 border-2 bg-white border-neutral50 px-3 rounded-md"
              >
                <AccordionItem value={`${index}`}>
                  <AccordionTrigger>
                    <p className="text-lg text-left font-semibold">
                      {faq.heading}
                    </p>
                  </AccordionTrigger>
                  <AccordionContent>
                    <p className="text-neutral300">{faq.description}</p>
                  </AccordionContent>
                </AccordionItem>
              </div>
            ))}
          </div>
        </Accordion>
      </div>
    </section>
  );
};

export default FAQs;
