import { ArrowStraightUpRightIcon } from "@/assets/Icons";
import { Link } from "react-router-dom";
import { DrivexForcesLoader } from "@/components/SkeletonLoaders/AboutUs";
import Image from "@/components/ui/image";

type IProps = {
  drivexForcesData: any;
  isFetchingforcesData: boolean;
};

const DrivexForce = ({ drivexForcesData, isFetchingforcesData }: IProps) => {
  const skeletonLoader = Array.from({ length: 6 }, (_, index) => (
    <DrivexForcesLoader key={index} />
  ));

  return (
    <>
      <p className="text-xl lg:text-center font-medium">
        Force <span className="text-primaryA2">behind DriveX</span>
      </p>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {isFetchingforcesData
          ? skeletonLoader
          : drivexForcesData?.map((item: any, index: number) => (
              <div key={index} className="flex gap-4 w-full">
                <Image
                  className="object-cover w-[112px] h-[112px] rounded-lg"
                  src={item?.attributes?.Image?.data?.attributes?.url as string}
                  alt={item?.attributes?.Name}
                />
                <div className="flex flex-col justify-center gap-[4%]">
                  <p className="text-sm font-medium">
                    {item?.attributes?.Name}
                  </p>
                  <p className="text-sm font-medium text-primaryA2 sm:whitespace-nowrap">
                    {item?.attributes?.Position}
                  </p>
                  <p className="text-neutral300 text-xs w-[10rem] h-[2rem] text-wrap line-clamp-2">
                    {item?.attributes?.Description}
                  </p>
                  <div className="flex gap-2 items-center">
                    <Link
                      to={item?.attributes?.Linkedin_url}
                      target="_blank"
                      className="text-primaryB1 text-xs font-medium underline"
                    >
                      LinkedIn
                    </Link>
                    <ArrowStraightUpRightIcon />
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default DrivexForce;
