import {
  DISABLE_IMAGE_KIT,
  DMS_ASSETS_BASE_URL,
  IMAGE_KIT_BASE_URL,
  S3_BASE_URL,
} from "./constants";

export const getTransformedSrc = (src: string, transform?: string) => {
  const baseUrl = DISABLE_IMAGE_KIT
    ? S3_BASE_URL || DMS_ASSETS_BASE_URL
    : IMAGE_KIT_BASE_URL;

  const sourceBaseUrl =
    [S3_BASE_URL, DMS_ASSETS_BASE_URL].find((url) => src?.includes(url)) ||
    baseUrl;

  const transformedUrl = src?.replace(sourceBaseUrl, baseUrl);

  const transformedSrc =
    !DISABLE_IMAGE_KIT && transform
      ? `${transformedUrl}?tr=${transform}`
      : transformedUrl;

  return { transformedSrc };
};
