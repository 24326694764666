import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
} from "@/components/ui/dialog";
import { ChevronLeft as LeftIcon } from "lucide-react";
import firstOwner from "@/assets/svg/Filters/1stOwner.svg";
import secondOwner from "@/assets/svg/Filters/2ndOwner.svg";
import thirdOwner from "@/assets/svg/Filters/3rdOwner.svg";
import { Checkbox } from "@/components/ui/checkbox";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { useLocation } from "react-router-dom";
import MultiFilterAccordion from "./MultiFilterAccordion";
import NonCheckboxMultiFilter from "./NonCheckboxMultiFilter";
import SingleCheckboxFilter from "./SingleCheckboxFilter";
import { filterCategory } from "./data";
import { useLazyFiltersCountQuery } from "@/store/services/filter";
import { parseQueryString } from "@/utils/url";
import { track } from "@/utils/mixpanel/actions";
import { FILTER_APPLIED } from "@/utils/mixpanel/Events/filter_clicked_events";
import {
  getLocalstorageValueWithExpiry,
  setLocalstorageValueWithExpiry,
} from "@/utils/localstorage";
import {
  DESKTOP_NAVBAR_HEIGHT,
  LOCALSTORAGE_EXPIRY_TIME_FOR_FILTER,
  PLP_FILTERS_DEBOUNCE_VALUE,
  PLP_SKELETON_LOADER_DELAY,
} from "@/utils/constants";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import useDebouncedApiCall from "@/hooks/useDebounce";
import { deepEqual } from "@/utils/object";
import { useSelector } from "react-redux";

interface IProps {
  navigate: any;
  query: string;
  cityName: string;
  openType: string;
  setOpenType: React.Dispatch<React.SetStateAction<string>>;
  searchParams: any;
  setSearchParams: any;
  removeFilterCategory?: string;
  removeFilterCategoryValue?: string;
  selectedKey: string;
  setSelectedKey: React.Dispatch<React.SetStateAction<string>>;
  selectedFilters: object | any;
  setSelectedFilters: React.Dispatch<React.SetStateAction<object | any>>;
  filterInfo: object | any;
  setFilterInfo: React.Dispatch<React.SetStateAction<object | any>>;
  setFiltersCountLoading?: React.Dispatch<React.SetStateAction<boolean | any>>;
}

const defaultFilters: { [key: string]: string } = {
  bikeType: "",
  price: "",
  makeModel: "",
  kmsDriven: "",
  ownership: "",
  year: "",
  store: "",
};

const FilterView = ({
  navigate,
  query,
  cityName,
  openType,
  setOpenType,
  searchParams,
  setSearchParams,
  removeFilterCategory,
  removeFilterCategoryValue,
  selectedKey,
  setSelectedKey,
  selectedFilters,
  setSelectedFilters,
  filterInfo,
  setFilterInfo,
  setFiltersCountLoading,
}: IProps) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [clearAll, setClearAll] = useState(false);
  const [getFiltersCountData] = useLazyFiltersCountQuery();
  const location = useLocation();
  const isQueryFilter = searchParams.get("f");
  const isNoSelectedFilters = (obj: object) =>
    Object.values(obj).every((value) => value === "");
  const { isMobile, isDesktop } = useScreenDetector();
  const finalFilterCategories = removeFilterCategory
    ? filterCategory.filter((item) => item.value !== removeFilterCategory)
    : filterCategory;

  // This function checks the stores having count greater than zero and replaces those entries with the default
  // store in localstorage enabling all the stores to be visible along with the ones having a count greater than zero.
  function updateStoreBasedOnCount(
    storesFromApi: any[],
    storesFromLocalstorage: any[],
  ) {
    if (!storesFromLocalstorage) return;

    storesFromLocalstorage.forEach((storeFromLocalstorage, index) => {
      const storeWithCount = storesFromApi.find(
        (storeFromApi) =>
          storeFromApi.name === storeFromLocalstorage.name &&
          storeFromApi.count > 0,
      );
      if (storeWithCount) {
        storesFromLocalstorage[index] = storeWithCount;
      } else {
        storesFromLocalstorage[index].count = 0;
      }
    });
    return storesFromLocalstorage;
  }

  // This function checks the makeModel having count greater than zero from the api and replaces those entries with the default
  // makeModel in localstorage enabling all the models to be visible along with the ones having a count greater than zero.
  function updateMakeModelBasedOnCount(
    makeModelsFromApi: { [x: string]: any[] },
    makeModelsFromLocalstorage: {
      [x: string]: {
        name: any;
        count: number;
      }[];
    },
  ) {
    if (!makeModelsFromLocalstorage) return;

    for (const brand in makeModelsFromLocalstorage) {
      if (
        Object.prototype.hasOwnProperty.call(
          makeModelsFromLocalstorage,
          brand,
        ) &&
        Object.prototype.hasOwnProperty.call(makeModelsFromApi, brand)
      ) {
        makeModelsFromLocalstorage[brand].forEach(
          (makeModelFromLocalstorage, index) => {
            const makeModelWithCount = structuredClone(
              makeModelsFromApi[brand].find(
                (makeModelFromApi: { name: any; count: number }) =>
                  makeModelFromApi.name === makeModelFromLocalstorage.name &&
                  makeModelFromApi.count > 0,
              ),
            );

            if (makeModelWithCount) {
              makeModelsFromLocalstorage[brand][index] = makeModelWithCount;
              makeModelFromLocalstorage.count = makeModelWithCount.count;
            } else {
              // Set count to 0 if no match found in makeModelFromLocalstorage
              makeModelFromLocalstorage.count = 0;
            }
          },
        );
      }
    }

    // Update count to 0 for brands in makeModelsFromLocalstorage not present in makeModelsFromApi
    for (const brand in makeModelsFromLocalstorage) {
      if (!Object.prototype.hasOwnProperty.call(makeModelsFromApi, brand)) {
        makeModelsFromLocalstorage[brand].forEach(
          (entry: { count: number }) => {
            entry.count = 0;
          },
        );
      }
    }

    return makeModelsFromLocalstorage;
  }

  const fetchFiltersCount = async (clear: boolean | any = false) => {
    let res;
    const store = getLocalstorageValueWithExpiry("store");
    const makeModel = getLocalstorageValueWithExpiry("makeModel");

    const payload = {
      cityName,
      searchQuery: encodeURIComponent(query),
      filters: clear
        ? removeFilterCategory
          ? {
              [removeFilterCategory]: removeFilterCategoryValue,
            }
          : null
        : Object.fromEntries(
            Object.entries(
              removeFilterCategory
                ? {
                    ...selectedFilters,
                    store: removeFilterCategoryValue,
                  }
                : selectedFilters,
            ).filter(([, value]) => value !== ""),
          ), // This will return undefined for the selectedFilters that do not have a value yet, so that we send only what we need to the request payload
    };

    if (!store && !makeModel) {
      res = await getFiltersCountData({
        cityName,
        filters: removeFilterCategory
          ? { [removeFilterCategory]: removeFilterCategoryValue }
          : undefined,
      });

      setLocalstorageValueWithExpiry(
        "store",
        JSON.stringify(res?.data?.data.facetsCounts.store),
        LOCALSTORAGE_EXPIRY_TIME_FOR_FILTER,
      );
      setLocalstorageValueWithExpiry(
        "makeModel",
        JSON.stringify(res.data.data.facetsCounts.makeModel),
        LOCALSTORAGE_EXPIRY_TIME_FOR_FILTER,
      );

      if (!isNoSelectedFilters(selectedFilters)) {
        res = await getFiltersCountData(payload);
      }
    } else {
      res = await getFiltersCountData(payload);
    }

    const updatedStore = updateStoreBasedOnCount(
      res?.data?.data.facetsCounts.store,
      JSON.parse(getLocalstorageValueWithExpiry("store") || "null"),
    );

    const updatedMakeModel = updateMakeModelBasedOnCount(
      res.data.data.facetsCounts.makeModel,
      JSON.parse(getLocalstorageValueWithExpiry("makeModel") || "null"),
    );

    setTotalCount(res.data.data.totalCounts);
    setFilterInfo({
      ...res.data.data,
      facetsCounts: {
        ...res.data.data.facetsCounts,
        store: updatedStore,
        makeModel: updatedMakeModel,
      },
    });

    // deepEqual is required to prevent infinite loop (because selectedFilters state gets updated first, so if selectedFilters & the filters in query string arent equal
    // only then we allow applying filters for desktop, otherwise it goes into an infinite loop when both are empty)
    if (
      isDesktop &&
      (!isNoSelectedFilters(selectedFilters) || isQueryFilter) &&
      !deepEqual(selectedFilters, {
        ...defaultFilters,
        ...parseQueryString(decodeURIComponent(isQueryFilter)),
      })
    ) {
      handleApplyFilters();
      setTimeout(() => {
        setFiltersCountLoading && setFiltersCountLoading(false);
      }, PLP_SKELETON_LOADER_DELAY);
    }
  };

  const debouncedFetchFiltersCount = useDebouncedApiCall<object>(
    fetchFiltersCount,
    PLP_FILTERS_DEBOUNCE_VALUE,
  );

  useEffect(() => {
    if (openType === "filter" && isMobile) {
      fetchFiltersCount();
    }
  }, [openType, selectedKey]);

  useEffect(() => {
    if (isDesktop) {
      debouncedFetchFiltersCount();
    }
  }, [selectedFilters, removeFilterCategoryValue, cityName]);

  useEffect(() => {
    localStorage.removeItem("store");
    localStorage.removeItem("makeModel");
  }, [location.pathname]);

  const getFiltersFromUrl = () => {
    const queryFilters = decodeURIComponent(isQueryFilter);

    if (queryFilters === "null") {
      setSelectedFilters(defaultFilters);
    } else {
      setSelectedFilters((prev: any) => ({
        ...prev,
        ...parseQueryString(queryFilters),
      }));
    }
  };

  useEffect(() => {
    //This useEffect will only run when the makeModel in the url filter has just a brand value

    if (!isDesktop && clearAll) return;

    if (!isQueryFilter) return;

    const brandName =
      isQueryFilter && parseQueryString(isQueryFilter).makeModel;

    if (!brandName || brandName.split(",").length > 1) return;

    const brandData = Object.keys(filterInfo?.facetsCounts?.makeModel || {});

    // checks if the makeModel in url is a brand or not
    if (
      !brandData
        .map((item) => item.toLowerCase())
        .includes(brandName.toLowerCase())
    ) {
      return;
    }

    function getModelsArrayByBrand(data: any, brand: string) {
      for (const key in data) {
        if (
          Object.prototype.hasOwnProperty.call(data, key) &&
          key.toLowerCase() === brand
        ) {
          return data[key];
        }
      }
      return [];
    }

    const brandModels = getModelsArrayByBrand(
      filterInfo?.facetsCounts?.makeModel,
      parseQueryString(isQueryFilter).makeModel.toLowerCase(),
    );

    // pre fill the values of the selected brand with all its respective model names & update the selected filters state
    setSelectedFilters((prev: any) => ({
      ...prev,
      makeModel: brandModels.map((item: { name: any }) => item.name).join(","),
    }));

    return () => {
      if (!isDesktop) setClearAll(false);
    };
  }, [filterInfo]);

  useEffect(() => {
    if (!isQueryFilter) return;

    getFiltersFromUrl();
  }, []);

  const handleFilterSelection = (
    category: string,
    value: string | number,
    filterType = "multi",
    count: number,
    deselectAll = false,
  ) => {
    if (isDesktop) setFiltersCountLoading && setFiltersCountLoading(true);
    setSelectedFilters((prevState: typeof selectedFilters) => {
      const currentSelection =
        prevState[category as keyof typeof selectedFilters];
      if (
        currentSelection.split(",").includes(value.toString()) ||
        deselectAll
      ) {
        let updatedSelection;
        if (deselectAll) {
          // To deselect all models of a particular brand when the brand name checkbox is clicked (for make model category)
          updatedSelection = currentSelection
            .split(",")
            .filter((item: string) => !value.toString().includes(item))
            .join(",");
        } else {
          // If the value is already selected, remove it (this is for individual selection)
          updatedSelection = currentSelection
            .split(",")
            .filter((item: string) => item !== value.toString())
            .join(",");
        }

        setTotalCount((prev: any) => {
          if (prev === null) {
            return filterInfo?.totalCounts - count;
          }
          return prev - count;
        });

        return {
          ...prevState,
          [category]: updatedSelection,
        };
      } else {
        if (filterType === "multi") {
          if (selectedFilters[selectedKey] === "") {
            setTotalCount(count);
          } else {
            setTotalCount((prev: any) => prev + count);
          }
        } else {
          setTotalCount(count);
        }
        // Otherwise, add it to the selection
        return {
          ...prevState,
          [category]:
            currentSelection && filterType === "multi"
              ? `${currentSelection},${value}`
              : `${value}`,
        };
      }
    });
  };

  const handleCategoryClick = (category: string) => {
    setSelectedKey(category);
  };

  const getOwnershipImage = (index: number) => {
    switch (index) {
      case 0:
        return firstOwner;
      case 1:
        return secondOwner;
      case 2:
        return thirdOwner;
    }
  };

  const renderUiType = (
    item: { value: string | number; name: string; count: number },
    index: number,
    selectedKey: string,
  ) => {
    switch (selectedKey) {
      case "bikeType":
      case "ownership":
        return (
          <NonCheckboxMultiFilter
            handleFilterSelection={handleFilterSelection}
            selectedKey={selectedKey}
            selectedFilters={selectedFilters}
            item={item}
            index={index}
            getOwnershipImage={getOwnershipImage}
          />
        );
      case "price":
      case "kmsDriven":
      case "year":
      case "store":
        return (
          <div
            onClick={() =>
              handleFilterSelection(
                selectedKey,
                item.value ? item.value : item.name,
                selectedKey === "store" ? "multi" : "single",
                item.count,
              )
            }
            className="flex-between items-center text-sm"
          >
            <div className="flex gap-2 justify-center">
              <p key={index}>
                {["year", "kmsDriven", "price"].includes(selectedKey) ? (
                  <SingleCheckboxFilter
                    item={item}
                    selectedFilters={selectedFilters}
                    selectedKey={selectedKey}
                  />
                ) : (
                  // Multi Filter checkbox
                  <Checkbox
                    checked={
                      selectedFilters[selectedKey]
                        .split(",")
                        .includes(
                          item.value ? (item.value as string) : item.name,
                        )
                        ? true
                        : false
                    }
                    className="border-neutral300 w-[20px] h-[20px]"
                  />
                )}
              </p>
              <p className="text-neutral300">{item.name}</p>
            </div>
            <p className="text-neutral200">{item.count}</p>
          </div>
        );
    }
  };

  const renderFilters = (selectedKey: string) => {
    if (!selectedKey) return null;

    const selectedData = filterInfo?.facetsCounts?.[selectedKey];

    if (Array.isArray(selectedData)) {
      return selectedData.map((item, index) =>
        renderUiType(item, index, selectedKey),
      );
    } else if (typeof selectedData === "object") {
      return (
        <MultiFilterAccordion
          selectedData={selectedData}
          handleFilterSelection={handleFilterSelection}
          selectedKey={selectedKey}
          selectedFilters={selectedFilters}
        />
      );
    } else {
      return null;
    }
  };

  const handleApplyFilters = () => {
    track(FILTER_APPLIED, {
      filter_option: selectedFilters,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });

    const queryFiltersString = Object.entries(selectedFilters)
      .filter(([, value]) => value) // Filter out key-value pairs where value is not present
      .map(([key, value]) => `${key}:${value}`)
      .join("::");

    if (queryFiltersString === "") {
      searchParams.delete("f");
      setSearchParams(searchParams);
      if (isMobile) setOpenType("");
      return;
    }

    if (query !== "") {
      navigate(
        `${location.pathname}?q=${query}&f=${encodeURIComponent(queryFiltersString)}`,
      );
    } else {
      navigate(
        `${location.pathname}?f=${encodeURIComponent(queryFiltersString)}`,
      );
    }

    if (isMobile) setOpenType("");
  };

  return (
    <>
      {isMobile ? (
        <Dialog open={openType === "filter" && true}>
          <DialogContent className="bg-neutral30 flex flex-col h-full gap-0 p-0 border-0">
            <div className="flex gap-4 border-b-[1px] p-4 justify-between">
              <div className="flex gap-3">
                <DialogClose
                  onClick={() => {
                    setOpenType(""), getFiltersFromUrl();
                  }}
                >
                  <LeftIcon />
                </DialogClose>

                <p>Filters</p>
              </div>

              <p
                onClick={() => {
                  setSelectedFilters(
                    removeFilterCategory
                      ? {
                          ...defaultFilters,
                          [removeFilterCategory]: undefined,
                        }
                      : defaultFilters,
                  ),
                    !isDesktop && setClearAll(true),
                    fetchFiltersCount(true);
                }}
                className="text-primaryA2"
              >
                Clear all
              </p>
            </div>

            <div className="flex flex-grow overflow-auto">
              <div className="w-2/6 bg-neutral30 flex flex-col text-neutral300 text-sm p-0">
                {finalFilterCategories.map((category, index) => (
                  <div
                    key={index}
                    onClick={() => handleCategoryClick(category.value)}
                    className={cn(
                      "flex flex-between items-center relative py-4 px-2.5",
                      selectedKey === category.value && "bg-white",
                    )}
                  >
                    {selectedKey === category.value && (
                      <div className="w-[5px] absolute left-[0px] h-full bg-primaryA2"></div>
                    )}
                    <p>{category.name}</p>

                    {selectedFilters[category.value]?.split(",")[0] !== "" && (
                      <p className="text-primaryA2 text-sm">
                        {selectedFilters[category.value]?.split(",").length}
                      </p>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex-1 bg-white p-4 flex flex-col gap-3 overflow-auto pb-[85px]">
                {renderFilters(selectedKey)}
              </div>
            </div>

            <DialogFooter className="flex-row w-full border-t border-neutral50 bg-white justify-between p-4 items-center fixed bottom-0">
              <div className="flex flex-col">
                <p className="text-lg font-semibold">
                  {selectedFilters[selectedKey] === "" || totalCount === null
                    ? filterInfo?.totalCounts
                    : totalCount}
                </p>
                <p className="text-sm text-neutral300">Vehicles found</p>
              </div>

              <Button
                onClick={handleApplyFilters}
                variant="outline"
                className="bg-primaryA2 border-none text-white w-fit text-base px-14 py-6 hover:bg-primaryA2 hover:text-white"
              >
                Apply
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      ) : (
        <div>
          <div className="flex-col flex-grow overflow-auto rounded-sm h-[calc(100dvh-8rem)] w-fit sticky top-[98px]">
            <Accordion
              defaultValue={["0", "1", "2", "3", "4", "5", "6"]}
              type="multiple"
              className="w-[300px] gap-2.5 bg-neutral30 flex flex-col text-neutral300 text-sm"
              style={{
                height: `calc(100vh - ${DESKTOP_NAVBAR_HEIGHT}px)`,
              }}
            >
              {finalFilterCategories.map((category, index) => (
                <AccordionItem
                  key={index}
                  value={`${index}`}
                  className={cn(
                    "flex flex-col rounded-sm bg-white flex-between relative py-0 px-2.5",
                  )}
                >
                  <AccordionTrigger>
                    <p className="lg:text-black">{category.name}</p>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col gap-3 overflow-auto">
                    {renderFilters(category.value)}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterView;
