import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { X as CloseIcon } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import UserDetails from "./UserDetails";
import BuySell from "./BuySell";
import { useSelector } from "react-redux";
import UserData from "./UserData";
import { getSidebarClickedButton } from "./data";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import { getPageSource } from "@/utils/mixpanel/pageSource";
import { track } from "@/utils/mixpanel/actions";
import { ACCOUNT_OPENED } from "@/utils/mixpanel/Events/auth_clicked_events";
import MoreOptions from "./MoreOptions";
import { DriveXLogo } from "@/assets/Icons";

interface IProps {
  openSidebar: boolean;
  setOpenSidebar: any;
}

const Sidebar = ({ openSidebar, setOpenSidebar }: IProps) => {
  const navigate = useNavigate();
  const { isMobile } = useScreenDetector();
  const user = useSelector((state: any) => state.user.currentUser);
  const location = useLocation();
  const pageSource = getPageSource(location.pathname);

  const handleOnUserDetailsClick = () => {
    if (user) {
      track(ACCOUNT_OPENED, {
        source: pageSource,
        tab: "Buy",
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });

      navigate("/profile");
      return;
    }

    navigate(`${location.pathname}?login=true`, setOpenSidebar(false));
  };

  const handleUserDataClick = (value: string) => {
    setOpenSidebar(false);

    if (!user && value !== "contactUs") {
      navigate(`${location.pathname}?login=true`, setOpenSidebar(false));
      return;
    }

    if (value === "sell") {
      navigate("/profile", { state: { text: "sell" } });
      return;
    }

    const URL = getSidebarClickedButton(value);
    navigate(`${URL}`);
  };
  return (
    <>
      <Sheet open={openSidebar} onOpenChange={() => setOpenSidebar(false)}>
        <SheetContent
          side={isMobile ? "left" : "right"}
          className="w-[80%] flex flex-col justify-between gap-4 p-2 px-3 overflow-y-auto h-dvh"
        >
          <div className="flex flex-col gap-4">
            <SheetHeader>
              <div className="flex justify-between border-b items-center py-4">
                <span
                  data-test-id="drivex-logo"
                  onClick={() => setOpenSidebar(false)}
                >
                  <DriveXLogo size={86} />
                </span>
                <SheetClose>
                  <CloseIcon size={20} onClick={() => setOpenSidebar(false)} />
                </SheetClose>
              </div>
            </SheetHeader>

            <div className="flex gap-4 py-2">
              <UserDetails
                user={user}
                handleOnUserDetailsClick={handleOnUserDetailsClick}
              />
            </div>

            <div className="flex gap-3 flex-wrap">
              <UserData handleUserDataClick={handleUserDataClick} />
            </div>

            <div className="flex flex-col p-2">
              <MoreOptions />
            </div>
          </div>

          <div className="flex flex-col mb-1 gap-2 w-full">
            <BuySell setOpenSidebar={setOpenSidebar} navigate={navigate} />
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};

export default Sidebar;
