import { Key, useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard/index.tsx";
import Navbar from "@/components/Navbar";
import { Dialog } from "@/components/ui/dialog";
import Location from "@/components/Location";
import { useProductListQuery } from "../../store/services/product.ts";
import { initialize_aloglia, algoliaConfigs } from "@/utils/algolia/actions.ts";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import FilterView from "./FiltersView/index.tsx";
import BottomNavigation from "@/components/BottomNavigation/index.tsx";
import Sort from "./Sort.tsx";
import { X as CloseIcon } from "lucide-react";
import { productListingTabsData } from "@/components/BottomNavigation/data.tsx";
import { parseQueryString } from "@/utils/url.ts";
import {
  EMI_BANNER_INDEX,
  EMI_BANNER_DESKTOP_INDEX,
  NAVBAR_WITH_FILTER_CHIPS,
  BOTTOM_NAVIGATION_HEIGHT,
  EMI_BANNER_TABLET_INDEX,
  MOBILE_LISTING_PAGE_SIZE,
  DESKTOP_LISTING_PAGE_SIZE,
} from "@/utils/constants.ts";
import EmiPlan from "../Home/EmiPlan.tsx";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import { getWishListedVehicle } from "@/store/services/scheduleApi";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrRemoveWishlist,
  setIsWishListed,
} from "@/store/features/wishListSlice.tsx";
import { filterCategory } from "./FiltersView/data.ts";
import { DownArrowIcon } from "@/assets/Icons.tsx";
import Pill from "@/components/Pill/index.tsx";
import SEO from "@/components/SEO/index.tsx";
import { generateMetaInfo } from "../../../hyperion/seoData";
import { track } from "@/utils/mixpanel/actions.ts";
import { BOTTOMNAVIGATION_SORT_APPLIED_EVENT } from "@/utils/mixpanel/Events/bottomNavigation_clicked_events.ts";
import { PLP_VEHICLE_CARD_CLICKED_EVENT } from "@/utils/mixpanel/Events/plp_clicked_events.ts";
import { cn } from "@/lib/utils.ts";
import { pluralize } from "@/utils/pluralize.ts";
import aa from "search-insights";
import { PRODUCT_CARD_CLICK_EVENT } from "@/utils/algolia/events.ts";
import { QUICK_FILTER_CLICKED } from "@/utils/mixpanel/Events/filter_clicked_events.ts";
import { useCurrentCity } from "@/hooks/useCurrentCity.tsx";
import { setProductListSessionState } from "@/store/features/productListingSlice.ts";
import { useToast } from "@/components/ui/use-toast.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { sortContent } from "./data.tsx";
import Chips from "@/components/SkeletonLoaders/Chips/index.tsx";
import { useLazySearchRecommendationsQuery } from "@/store/services/search.ts";
import NoVehiclesFound from "./NoVehiclesFound/index.tsx";
import VehicleListLayout from "@/components/VehicleListLayout/index.tsx";
import useDidMount from "@/hooks/useDidMount.ts";
import VehicleListSkeletonLoader from "@/components/VehicleListSkeletonLoader/index.tsx";
import FlatList from "@/components/FlatList/index.tsx";
import { useGetAllStoresQuery } from "@/store/services/dealer.ts";
import StoreCard from "@/components/Store/StoreCard.tsx";
import StoreCardSkeletonLoader from "@/components/SkeletonLoaders/Store/index.tsx";
import ScrollView from "@/components/ScrollView/index.tsx";
import { STORE_CARD_CLICKED_EVENT } from "@/utils/mixpanel/Events/store_clicked_events.ts";
import mixpanel from "mixpanel-browser";
import { usePendingAction } from "@/hooks/usePendingAction.tsx";
import * as Sentry from "@sentry/browser";

interface typeOfSortSelection {
  id: number;
  sortText: string;
  sort: string;
  sortBy: string;
}

const defaultFilters: { [key: string]: string } = {
  bikeType: "",
  price: "",
  makeModel: "",
  kmsDriven: "",
  ownership: "",
  year: "",
  store: "",
};

const ProductListing = () => {
  const { isMobile, isTablet, isDesktop, isLargeScreen } = useScreenDetector();
  let { location } = useSelector((state: any) => state.location);
  location = location?.replaceAll("-", " ");
  const { wishList } = useSelector((state: any) => state.wishList);
  const { productListSessionState } = useSelector(
    (state: any) => state.productList,
  );
  const { handleLocationSelection, city } = useCurrentCity();
  const didMount = useDidMount();
  const user = useSelector((state: any) => state.user.currentUser);
  const [openLocation, setOpenLocation] = useState(false);
  const [openType, setOpenType] = useState("");
  const [searchRecommendations, setSearchRecommendations] = useState([]);
  const [isSearchRecommendationsLoading, setIsSearchRecommendationsLoading] =
    useState(false);
  const [selectedSortType, setSelectedSortType] =
    useState<typeOfSortSelection | null>(null);
  const pathLocation = useLocation();
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useDispatch();
  const { toast } = useToast();
  const { handlePendingAction } = usePendingAction();
  const currentUrl = pathLocation.pathname;
  const metaData = generateMetaInfo({ path: currentUrl, city });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q") || "";
  const queryFilter = searchParams.get("f");
  const [page, setPage] = useState(
    productListSessionState ? productListSessionState.page : 0,
  );
  const [filtersCountLoading, setFiltersCountLoading] = useState(false);
  const filtersFromUrl = !queryFilter
    ? {}
    : {
        ...parseQueryString(decodeURIComponent(queryFilter || "")),
      };
  const { data: productResultsData, isFetching } = useProductListQuery({
    cityName: location,
    searchQuery: encodeURIComponent(query),
    filters: Object.fromEntries(
      Object.entries(filtersFromUrl).filter(([, value]) => value !== ""),
    ),
    sort: selectedSortType?.sort,
    sortBy: selectedSortType?.sortBy,
    page,
    hitsPerPage:
      isMobile || isTablet || isLargeScreen
        ? MOBILE_LISTING_PAGE_SIZE
        : DESKTOP_LISTING_PAGE_SIZE,
  });
  const [getSearchRecommendations] = useLazySearchRecommendationsQuery();
  let productResults = productResultsData?.data?.vehicles;
  const hasMore =
    productResultsData?.data?.page < productResultsData?.data?.totalPages;
  const productTotalCounts = productResultsData?.data?.totalCounts;
  const queryId = productResultsData?.data?.queryId;
  const [paginatedProductsData, setPaginatedProductsData] = useState<
    IVehicleInfo[]
  >(
    productListSessionState
      ? productListSessionState.paginatedProductsData
      : [],
  );
  const [filterInfo, setFilterInfo] = useState<any>({});
  const [selectedFilters, setSelectedFilters] = useState(defaultFilters);
  const [selectedKey, setSelectedKey] = useState<string>("bikeType");
  const selectedFilterCategories = filterCategory.map((category) => {
    const key = category.value;
    const value = (filtersFromUrl as any)[key];
    const count = value ? value.split(",").length : 0;
    return { name: category.name, count, value: category.value };
  });

  const {
    data: storesData,
    isFetching: isStoresFetching,
    isError: isStoresError,
  } = useGetAllStoresQuery({
    cityName: location,
  });

  const stores: IStore[] = storesData?.data?.stores;
  const storesCount = stores?.length;

  const selectedFilterValues: string[] | any = Object.entries(
    structuredClone(selectedFilters),
  )
    ?.flatMap(([key, value]) =>
      value.includes(",")
        ? value.split(",").map((val) => ({
            category: key,
            value: val,
            name:
              key === "makeModel"
                ? val
                : filterInfo?.facetsCounts?.[key]?.find(
                    (item: { value: string }) =>
                      item?.value.toString() === val.toString(),
                  )?.name,
          }))
        : {
            category: key,
            value,
            name:
              key === "makeModel"
                ? value
                : Array.isArray(filterInfo?.facetsCounts?.[key]) &&
                  filterInfo?.facetsCounts?.[key]?.find(
                    (item: { value: string }) => {
                      return item?.value?.toString() === value.toString();
                    },
                  )?.name,
          },
    )
    .filter((item) => item.value.length > 0);

  useEffect(() => {
    const handleScroll = () => {
      if (
        productListSessionState &&
        window.scrollY > productListSessionState?.scrollPosition
      ) {
        dispatch(setProductListSessionState(null));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // when user changes location, reset the data from prev location & scroll to top
  useEffect(() => {
    if (didMount) return;

    if (productListSessionState) {
      dispatch(setProductListSessionState(null));
    }

    resetPage();
  }, [location, queryFilter, query, selectedSortType]);

  // detects when user changes cityname & removes the applies filters
  useEffect(() => {
    // do nothing in the first render, run this useEffect only when location changes & not during component mounting phase
    if (didMount) return;

    const isLastVisitedPageHomeOrNone =
      pathLocation?.state?.from === "home" || pathLocation.key === "default";

    if (queryFilter && !isLastVisitedPageHomeOrNone) {
      searchParams.delete("f");
      setSearchParams(searchParams);
      setSelectedFilters(defaultFilters);
    }
  }, [location]);

  //This updates the paginatedProductsData state based on current & previous pages only if the api returns a list of data
  useEffect(() => {
    if (productListSessionState) {
      setPaginatedProductsData(productListSessionState.paginatedProductsData);
      setPage(productListSessionState.page);
      window.scrollTo({
        top: productListSessionState?.scrollPosition,
        behavior: "smooth",
      });
      return;
    }

    if (productResults?.length > 0) {
      setPaginatedProductsData((prev) => [...prev, ...productResults]);
    }
  }, [productResults]);

  // This is used to initialize algolia on list page & open the location modal if location hasn't been selected & get location from url and set it to the location store & track the page view event
  useEffect(() => {
    initialize_aloglia();

    mixpanel.track_pageview({
      city_name: location,
      page: "product_listing_page_viewed",
    });

    handleLocationSelection({
      pathname: currentUrl,
      setOpenLocation,
    });

    return () => {
      productResults = [];
    };
  }, []);

  useEffect(() => {
    if (
      !isFetching &&
      productResults?.length <= 0 &&
      paginatedProductsData.length <= 0 &&
      searchRecommendations.length <= 0
    ) {
      const fetchSearchRecommendationsData = async () => {
        setIsSearchRecommendationsLoading(true);
        const data = await getSearchRecommendations({
          cityName: location,
        });
        setSearchRecommendations(data?.data?.data);
        setIsSearchRecommendationsLoading(false);
      };

      fetchSearchRecommendationsData();
    }
  }, [paginatedProductsData, isFetching]);

  useEffect(() => {
    if (!user && wishList?.length <= 0) return;
    const wishListedVehicleAvailable = async () => {
      try {
        const fetchWishList = await getWishListedVehicle(`compact=true`);

        if (!fetchWishList) {
          dispatch(setIsWishListed([]));
        } else {
          dispatch(
            setIsWishListed([...new Set([...fetchWishList, ...wishList])]),
          );
        }
      } catch (err) {
        dispatch(setIsWishListed([]));
        Sentry.captureException(err);
      }
    };

    wishListedVehicleAvailable();
  }, [user]);

  const resetPage = () => {
    setPaginatedProductsData([]);
    setPage(0);
    window.scrollTo(0, 0);
  };

  const handleProductCardClick = (data: IVehicleInfo, index: number) => {
    aa("sendEvents", [
      algoliaConfigs({
        eventName: `${PRODUCT_CARD_CLICK_EVENT}:${data.vehicleName}`.slice(
          0,
          64,
        ),
        queryID: queryId,
        objectIDs: [`${data.objectID}`],
        positions: [index],
        eventType: "click",
        index: "reachX-search-engine-dev",
      }),
    ]);

    track(PLP_VEHICLE_CARD_CLICKED_EVENT, {
      vehicle_brand: data.brand,
      vehicle_model: data.modelName,
      store_location: data.storeName,
      vehicle_id: data.vehicleId,
      source: "PLP",
      position: index + 1,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });

    dispatch(
      setProductListSessionState({
        paginatedProductsData,
        page,
        scrollPosition: window.scrollY,
        prevPath: `${pathLocation.pathname}${pathLocation.search}`,
      }),
    );

    navigate(`${currentUrl}/${data.vehicleId}`, {
      state: { queryId, objectId: data?.objectID },
    });
  };

  const handleSortClick = (content: typeOfSortSelection | string | any) => {
    const desktopSelectedSortContent: any =
      isDesktop && sortContent.find((item) => item.sortText === content);

    track(BOTTOMNAVIGATION_SORT_APPLIED_EVENT, {
      sort_applied: isDesktop
        ? desktopSelectedSortContent.sortText
        : content.sortText,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    setSelectedSortType(isDesktop ? desktopSelectedSortContent : content);
    setOpenType("");
  };

  const handleBottomNavigationClick = (type: string) => {
    setOpenType(type);
  };

  const handleWishListClick = async (e: React.SyntheticEvent, product: any) => {
    e.stopPropagation();

    if (!user) {
      handlePendingAction({
        actionType: "UPDATE_WISHLIST",
        payload: [
          wishList,
          product,
          currentUrl,
          toast,
          city,
          user?.phoneNumber,
          dispatch,
        ],
      });
      setSearchParams({ login: "true" }, { preventScrollReset: true });
      return;
    }

    await addOrRemoveWishlist(
      wishList,
      product,
      currentUrl,
      toast,
      city,
      user?.phoneNumber,
      dispatch,
    );
  };

  const getEmiBannerIndex = () => {
    return isMobile
      ? EMI_BANNER_INDEX
      : isLargeScreen || isTablet
        ? EMI_BANNER_TABLET_INDEX
        : EMI_BANNER_DESKTOP_INDEX;
  };

  const handleFilterSelection = (category: string, value: string | number) => {
    setFiltersCountLoading(true);
    setSelectedFilters((prevState: any) => {
      const currentSelection =
        prevState[category as keyof typeof selectedFilters];
      if (currentSelection.split(",").includes(value.toString())) {
        // If the value is already selected, remove it (this is for individual selection)
        const updatedSelection = currentSelection
          .split(",")
          .filter((item: string) => item !== value.toString())
          .join(",");

        return {
          ...prevState,
          [category]: updatedSelection,
        };
      }
    });
  };

  const handleStoreCardClick = (store: IStore) => {
    track(STORE_CARD_CLICKED_EVENT, {
      store_name: store.store_name,
      city_name: store.city,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    navigate(`/${store.city}/${store.slug}`);
  };

  return (
    <>
      <SEO metaData={metaData} />
      <Navbar backgroundColor="bg-white" />

      {!location && openLocation ? (
        <Dialog open={openLocation}>
          <Location setOpenLocation={setOpenLocation} />
        </Dialog>
      ) : (
        <div className="bg-neutral30">
          {!isDesktop && (
            <>
              <div className="flex gap-4 overflow-auto bg-white p-4 pt-[129px] no-scrollbar  sticky top-0 z-10">
                {selectedFilterCategories
                  ?.sort((a, b) => b.count - a.count)
                  .map((categoryData, index) => (
                    <Pill
                      key={index}
                      className={
                        categoryData.count > 0
                          ? "bg-neutral30 border-orange-500"
                          : ""
                      }
                      childClassName={
                        categoryData.count > 0 ? "text-primaryA2" : ""
                      }
                      name={categoryData.name}
                      rightHalf={
                        <DownArrowIcon
                          colorOne={
                            categoryData.count <= 0 ? "#919191" : "#E63C32"
                          }
                          colorTwo={
                            categoryData.count <= 0 ? "#919191" : "#F47823"
                          }
                          fill={categoryData.count <= 0 ? "#919191" : "#E63C32"}
                        />
                      }
                      handleClick={() => {
                        track(QUICK_FILTER_CLICKED, {
                          filter_option: categoryData.name,
                          filter_value: categoryData.value,
                          name: user?.userName,
                          phone_number: user?.phoneNumber,
                        });
                        setSelectedKey(categoryData.value);
                        setOpenType("filter");
                      }}
                    />
                  ))}
              </div>
              <ScrollView
                data={stores}
                className="px-4 pt-4"
                isFetching={isStoresFetching}
                isError={isStoresError}
                renderItem={(store) => {
                  return (
                    <div
                      key={store.store_id}
                      className={cn(
                        "min-w-[85%] sm:min-w-[350px] pb-1",
                        stores?.length > 1 && "mr-4",
                      )}
                    >
                      <StoreCard
                        store={store}
                        handleStoreClick={handleStoreCardClick}
                      />
                    </div>
                  );
                }}
                SkeletonLoader={() => {
                  return (
                    <div className="min-w-[350px] mr-4">
                      <StoreCardSkeletonLoader />
                    </div>
                  );
                }}
              />
            </>
          )}

          <div
            className={cn(
              isDesktop && "flex p-4 lg:pt-24 max-w-screen-2xl w-full mx-auto",
            )}
          >
            {isDesktop && (
              <FilterView
                navigate={navigate}
                key={pathLocation.pathname}
                query={query}
                cityName={location}
                openType={openType}
                setOpenType={setOpenType}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                selectedKey={selectedKey}
                setSelectedKey={setSelectedKey}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                filterInfo={filterInfo}
                setFilterInfo={setFilterInfo}
                setFiltersCountLoading={setFiltersCountLoading}
              />
            )}
            <div
              className={cn(
                "bg-neutral30 p-4 lg:pr-0 lg:py-0 flex flex-col w-full overflow-hidden",
                paginatedProductsData?.length <= 0 &&
                  !isFetching &&
                  `bg-neutral30 w-full ${isDesktop && "flex flex-col items-center"}`,
              )}
              style={{
                minHeight: !isDesktop
                  ? `calc(100vh - ${parseInt(NAVBAR_WITH_FILTER_CHIPS) + parseInt(BOTTOM_NAVIGATION_HEIGHT)}px)`
                  : "",
              }}
            >
              {isDesktop && location && (
                <h6 className="text-base font-medium max-w-7xl mx-auto w-full">
                  <span className="text-primaryA2">Home</span> / Vehicles in{" "}
                  {location}
                </h6>
              )}

              {isDesktop && (
                <div className="flex flex-wrap gap-3 py-5 mr-auto max-w-7xl mx-auto w-full">
                  {selectedFilterValues.length > 0 && (
                    <Pill
                      className="bg-neutral700 rounded py-1.5 px-2 text-xs"
                      childClassName="text-white"
                      name="Clear all"
                      handleClick={() => {
                        setSelectedFilters(defaultFilters);
                      }}
                    />
                  )}
                  {selectedFilterValues.map(
                    (
                      filterValue: {
                        name: string;
                        value: string;
                        category: string;
                      },
                      index: Key | null | undefined,
                    ) => (
                      <Pill
                        key={index}
                        className="bg-white border-orange-500 rounded py-1.5 px-2"
                        childClassName="text-primaryA2"
                        name={filterValue.name}
                        rightHalf={<CloseIcon size={20} color="#E63C32" />}
                        handleClick={() =>
                          handleFilterSelection(
                            filterValue.category,
                            filterValue.value,
                          )
                        }
                      />
                    ),
                  )}
                </div>
              )}

              {isDesktop && (
                <ScrollView
                  data={stores}
                  className="w-full"
                  isFetching={isStoresFetching}
                  isError={isStoresError}
                  renderItem={(store) => {
                    return (
                      <div
                        key={store.store_id}
                        className={cn(
                          "mr-4 mb-4 cursor-pointer",
                          stores?.length > 1 ? "min-w-[350px]" : "w-[350px]",
                        )}
                      >
                        <StoreCard
                          store={store}
                          handleStoreClick={handleStoreCardClick}
                        />
                      </div>
                    );
                  }}
                  SkeletonLoader={() => {
                    return (
                      <div className="min-w-[350px] mr-4">
                        <StoreCardSkeletonLoader />
                      </div>
                    );
                  }}
                />
              )}

              {(isDesktop && filtersCountLoading) ||
              (isFetching && page === 0) ? (
                <div className="mr-auto">
                  <Chips />
                </div>
              ) : (
                <div
                  className={cn(
                    "flex justify-between items-center max-w-7xl mx-auto w-full",
                    !isDesktop && "unset",
                  )}
                >
                  {productTotalCounts > 0 && (
                    <h1 className="font-semibold text-lg">
                      <span className="text-primaryA2">
                        {productTotalCounts}
                      </span>
                      &nbsp;
                      <span className="text-primaryA2">{query}</span>
                      &nbsp;{pluralize(
                        productTotalCounts,
                        "vehicle",
                      )} across {storesCount} {pluralize(storesCount, "store")}
                    </h1>
                  )}

                  {isDesktop && productTotalCounts > 0 && (
                    <Select onValueChange={(value) => handleSortClick(value)}>
                      <SelectTrigger className="w-[300px]">
                        <SelectValue
                          placeholder={
                            selectedSortType
                              ? `Sort by: ${selectedSortType?.sortText}`
                              : `Sort by: ${sortContent[0].sortText}`
                          }
                        />
                      </SelectTrigger>
                      <SelectContent>
                        {sortContent.map((content, index) => (
                          <SelectItem
                            key={index}
                            className="focus:bg-neutral30"
                            value={content.sortText}
                          >
                            Sort by: {content.sortText}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                </div>
              )}

              <FlatList
                data={paginatedProductsData}
                renderItem={({ item, index, containerRef }) => (
                  <>
                    {index === getEmiBannerIndex() && (
                      <div
                        className="rounded-lg col-span-1 md:col-span-2 xl:col-span-3"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <EmiPlan className={"bg-white"} />
                      </div>
                    )}

                    <ProductCard
                      key={index}
                      product={item}
                      handleClick={() => handleProductCardClick(item, index)}
                      handleWishListClick={(e) => handleWishListClick(e, item)}
                      index={index}
                      containerRef={containerRef}
                      wishList={wishList}
                    />
                  </>
                )}
                keyExtractor={(item: any) => item.vehicleId.toString()}
                loadingMore={loadingMore}
                setLoadingMore={setLoadingMore}
                hasMore={hasMore}
                isFetching={isFetching}
                setPage={setPage}
                onEndReachedThreshold={0.5}
                ListEmptyComponent={
                  <div className="flex flex-col gap-8 w-full">
                    <NoVehiclesFound
                      setSelectedFilters={setSelectedFilters}
                      defaultFilters={defaultFilters}
                      pathLocation={pathLocation.pathname}
                    />

                    {isSearchRecommendationsLoading ? (
                      <div className="flex flex-col gap-2">
                        <div className="mr-auto">
                          <Chips />
                        </div>

                        <VehicleListSkeletonLoader />
                      </div>
                    ) : (
                      searchRecommendations?.length > 0 && (
                        <div className="flex flex-col">
                          <p className="text-xl">
                            Explore Popular{" "}
                            <span className="text-primaryA2">Bikes</span>
                          </p>

                          <VehicleListLayout>
                            {searchRecommendations.map(
                              (recommendation, index) => (
                                <ProductCard
                                  key={index}
                                  product={recommendation}
                                  handleClick={handleProductCardClick}
                                  handleWishListClick={handleWishListClick}
                                  index={index}
                                  wishList={wishList}
                                />
                              ),
                            )}
                          </VehicleListLayout>
                        </div>
                      )
                    )}
                  </div>
                }
                isListEmpty={paginatedProductsData?.length <= 0}
                isLoading={
                  (isDesktop && filtersCountLoading) ||
                  (isFetching && page === 0)
                }
                ListLoadingComponent={<VehicleListSkeletonLoader />}
              />
            </div>
          </div>

          <BottomNavigation
            handleClick={handleBottomNavigationClick}
            tabs={productListingTabsData}
          />

          <Sort
            openType={openType}
            setOpenType={setOpenType}
            selectedSortType={selectedSortType}
            handleSortClick={handleSortClick}
          />
        </div>
      )}

      {isMobile && (
        <FilterView
          navigate={navigate}
          query={query}
          cityName={location}
          openType={openType}
          setOpenType={setOpenType}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          selectedKey={selectedKey}
          setSelectedKey={setSelectedKey}
          key={pathLocation.key}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          filterInfo={filterInfo}
          setFilterInfo={setFilterInfo}
        />
      )}
    </>
  );
};

export default ProductListing;
