import BlogContent from "@/components/Blogs/BlogContent";
import BlogHeader from "@/components/Blogs/BlogHeader";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import { useGetBlogBySlugQuery } from "@/store/services/blog";
import { type BlocksContent } from "@strapi/blocks-react-renderer";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function BlogDetails() {
  const [blogDetails, setBlogDetails] = useState<IBlog>();
  const content: BlocksContent = blogDetails?.attributes.editor;
  const location = useLocation();
  const slug = location.pathname.split("/")[2];
  const { data } = useGetBlogBySlugQuery({
    slug,
    params: {
      "populate[0]": "cover",
      "populate[1]": "collection",
      "populate[2]": "authorsBio",
      "populate[3]": "authorsBio.avatar",
      publicationState: "preview",
    },
  });

  useEffect(() => {
    if (data && data.data) {
      setBlogDetails(data.data[0]);
    }
  }, [data]);

  if (!blogDetails) return;

  return (
    <section>
      <Navbar showSearchbar={false} backgroundColor="bg-white" />

      <div className="pt-[75px]" />
      <BlogHeader blog={blogDetails} />

      <div className="py-10 relative px-4 lg:px-0 max-w-screen-lg mx-auto w-full">
        <BlogContent content={content} />
      </div>

      <div className="flex flex-col gap-6 bg-neutral900 py-4">
        <Footer />
      </div>
    </section>
  );
}

export default BlogDetails;
