import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ConfirmWhatsappTour from "./ConfirmWhatsappTour";
import { scheduleWhatsappTour } from "@/store/services/scheduleApi";
import { WhatsappIcon } from "@/assets/Icons";
import {
  TEST_DRIVE_INITIATED,
  WHATSAPP_TOUR_INITIATED,
  WHATSAPP_TOUR_REQUESTED,
} from "@/utils/mixpanel/Events/test_drive_clicked_events";
import { track } from "@/utils/mixpanel/actions";
import { algoliaConfigs, initialize_aloglia } from "@/utils/algolia/actions";
import aa from "search-insights";
import {
  TEST_RIDE_CLICK_EVENT,
  WHATSAPP_TOUR_CLICK_EVENT,
} from "@/utils/algolia/events";
import { meta_conversion_api } from "@/utils/metaPixels";
import { getCurrentDate, getCurrentDateAndTime } from "@/utils/date";
import { usePendingAction } from "@/hooks/usePendingAction";
import { getReturningCustomerData } from "@/utils/returningCustomer";

interface IProps {
  testRideDetails?: { date: string; exists: boolean } | null;
  vehicleDetails?: {
    storeId: number;
    vehicleId: number;
    storeName: string;
    storeMapLink: string;
    storePhoneNumber: string;
    vehicleName: string;
    brand: string;
    modelName: string;
    price: {
      displayValue: string;
      unit: string;
      value: number;
    };
    objectID: string;
  };
  whatsappTourExists: {
    exists: boolean;
  } | null;
  fetchUpcomingWhatsappTour: () => void;
}

const TestRide = ({
  testRideDetails,
  vehicleDetails,
  whatsappTourExists,
  fetchUpcomingWhatsappTour,
}: IProps) => {
  const [confirmWhatsappTour, setConfirmWhatsappTour] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: any) => state.user.currentUser);
  const pendingAction = useSelector(
    (state: any) => state.pendingActions.pendingAction,
  );
  const { handlePendingAction } = usePendingAction();
  const currentDate = getCurrentDate();
  const openVideoTour = pendingAction?.openVideoTour;
  const hasPassedScheduleDate =
    testRideDetails && testRideDetails?.date < currentDate;
  const utm_parameters =
    sessionStorage.getItem("utm_paramters") &&
    JSON.parse(sessionStorage.getItem("utm_paramters") || "");

  useEffect(() => {
    initialize_aloglia();
  }, []);

  useEffect(() => {
    if (!openVideoTour || !user) return;

    setConfirmWhatsappTour(true);
    handlePendingAction({ actionType: "CLEAR_PENDING_ACTION" });
  }, [openVideoTour, user]);

  const sendAlgoliaEvent = (eventName: string) => {
    if (location?.state?.queryId) {
      aa(
        "addedToCartObjectIDsAfterSearch",
        algoliaConfigs({
          eventName: `${eventName}:${vehicleDetails?.vehicleName}`,
          queryID: location.state.queryId,
          objectIDs: [`${vehicleDetails?.objectID}`],
          eventType: "conversion",
          positions: undefined,
          index: "reachX-search-engine-dev",
        }),
      );
    }
  };

  function trackTestDriveInitiated() {
    track(TEST_DRIVE_INITIATED, {
      vehicle_id: vehicleDetails?.vehicleId,
      vehicle_brand: vehicleDetails?.brand,
      vehicle_model: vehicleDetails?.modelName,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  }

  function trackWhatsappTourInitiated() {
    track(WHATSAPP_TOUR_INITIATED, {
      vehicle_brand: vehicleDetails?.brand,
      vehicle_model: vehicleDetails?.modelName,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  }

  const handleTestRideClick = () => {
    sendAlgoliaEvent(TEST_RIDE_CLICK_EVENT);

    getReturningCustomerData({
      vehicleDetail: {
        type: "test-ride",
        date_time: getCurrentDateAndTime(), // sends the current date and time
        version: "v1.0-test-ride",
        data: { ...vehicleDetails },
      },
    });

    if (!user) {
      trackTestDriveInitiated();
      navigate(`${location.pathname}?login=true&redirect=test-ride`, {
        state: {
          source: "test-ride",
        },
        replace: true,
      });
      return;
    }

    if (testRideDetails?.exists && !hasPassedScheduleDate) {
      navigate(`/profile`);
      return;
    }

    trackTestDriveInitiated();

    navigate(`${location.pathname}/test-ride`, {
      state: {
        queryId: location?.state?.queryId,
        objectId: vehicleDetails?.objectID,
      },
    });
  };

  const whatsappTourClick = () => {
    const returningCustomerData =
      JSON.parse(localStorage.getItem("returningCustomerData") as string) || [];

    const isVehicleBookedForTestRide = returningCustomerData?.data?.some(
      (item: { data: { vehicleId: number }; type: string }) =>
        item?.data?.vehicleId === vehicleDetails?.vehicleId &&
        item?.type === "test-ride",
    );

    if (!isVehicleBookedForTestRide) {
      getReturningCustomerData({
        vehicleDetail: {
          type: "whatsapp-tour",
          date_time: getCurrentDateAndTime(), // sends the current date and time
          version: "v1.0-whatsapp-tour",
          data: { ...vehicleDetails },
        },
      });
    }

    if (!user) {
      handlePendingAction({ actionType: "OPEN_VIDEO_TOUR" });
      navigate(`${location.pathname}?login=true`, {
        state: {
          source: "whatsapp-tour",
        },
      });
      trackWhatsappTourInitiated();
      return;
    }

    trackWhatsappTourInitiated();
    setConfirmWhatsappTour(true);
  };

  const handleProceed = async () => {
    track(WHATSAPP_TOUR_REQUESTED, {
      vehicle_brand: vehicleDetails?.brand,
      vehicle_model: vehicleDetails?.modelName,
      vehicle_name: vehicleDetails?.vehicleName,
      phone_number: user?.phoneNumber,
      name: user?.userName,
      vehicle_url: window.location.href,
      store_name: vehicleDetails?.storeName,
      store_location: vehicleDetails?.storeMapLink,
    });

    sendAlgoliaEvent(WHATSAPP_TOUR_CLICK_EVENT);

    const message = encodeURIComponent(
      `Hi, I'm interested in this ${vehicleDetails?.vehicleName}: ${window.location.href}! Can I see it live on video call?`,
    );
    const whatsappURL = `https://wa.me/${vehicleDetails?.storePhoneNumber}?text=${message}`;
    window.open(whatsappURL, "_blank");

    if (!whatsappTourExists?.exists) {
      await scheduleWhatsappTour({
        store_id: vehicleDetails?.storeId,
        vehicle_id: vehicleDetails?.vehicleId,
        user_id: user?.id,
        user_name: user?.userName,
        phone_number: user?.phoneNumber,
        ...utm_parameters,
      });

      fetchUpcomingWhatsappTour();

      meta_conversion_api({
        eventName: "Lead",
        eventId: vehicleDetails?.vehicleId,
        eventType: "Video Tour",
        phoneNumber: user?.phoneNumber,
      });

      // eslint-disable-next-line
      // @ts-ignore
      window.fbq?.("track", "Lead");
    }
  };

  return (
    <div className="bg-white border-neutral50 w-full z-10 flex gap-2">
      <ConfirmWhatsappTour
        confirmWhatsappTour={confirmWhatsappTour}
        setConfirmWhatsappTour={setConfirmWhatsappTour}
        handleProceed={handleProceed}
      />

      <Button
        onClick={whatsappTourClick}
        variant="outline"
        className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
      >
        {whatsappTourExists?.exists ? (
          <>
            <WhatsappIcon /> Repeat Tour
          </>
        ) : (
          <>
            <WhatsappIcon /> Video Tour
          </>
        )}
      </Button>

      <Button
        onClick={handleTestRideClick}
        variant="outline"
        className={cn(
          "bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white",
          testRideDetails?.exists &&
            !hasPassedScheduleDate &&
            "bg-neutral30 flex flex-col gap-[1px] text-xs hover:bg-neutral30",
        )}
        disabled={user && !testRideDetails}
      >
        {testRideDetails?.exists && !hasPassedScheduleDate ? (
          <>
            <p className="text-primaryA2">Upcoming Test Drive</p>
            <p className="text-neutral300 text-xs">{testRideDetails?.date}</p>
          </>
        ) : (
          "Test Ride"
        )}
      </Button>
    </div>
  );
};

export default TestRide;
