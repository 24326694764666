import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  location: localStorage.getItem("location"),
  citiesData: [] as ICitiesData[],
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
      localStorage.setItem("location", action.payload);
    },
    setCitiesData: (state, action) => {
      state.citiesData = action.payload;
    },
  },
});

export const { setLocation, setCitiesData } = locationSlice.actions;

export default locationSlice.reducer;
