import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { QueryTypeData } from "../data.tsx";
import { cn } from "@/lib/utils.ts";

interface IProps {
  handleSelectValueChange: (value: string) => void;
  errorMsg: string;
}

const SelectOptions = ({ handleSelectValueChange, errorMsg }: IProps) => {
  return (
    <>
      <Select onValueChange={(value) => handleSelectValueChange(value)}>
        <SelectTrigger
          className={cn(
            "w-full data-[placeholder]:text-neutral300 bg-neutral30 focus-visible:ring-0 focus-visible:ring-offset-0",
            errorMsg === "Invalid TypeOfQuery" && "bg-red-100",
          )}
        >
          <SelectValue placeholder="Select an Enquiry Type" />
        </SelectTrigger>
        <SelectContent>
          {QueryTypeData.map((option, index) => (
            <SelectItem
              key={index}
              className="focus:bg-neutral30"
              value={option.option}
            >
              {option.option}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};

export default SelectOptions;
