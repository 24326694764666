import AvailabilityData from "@/components/AvailabilityData";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { IInspectionDetail, sellingSteps } from "@/pages/Sell/data";
import { useSelector } from "react-redux";
import FinalPriceEstimation from "../FinalPriceEstimation";
import { useEffect } from "react";
import { track } from "@/utils/mixpanel/actions";
import { PRICE_ESTIMATE_VIEWED } from "@/utils/mixpanel/Events/sell_flow_events";

const HeroSection = () => {
  const { isMobile } = useScreenDetector();
  const { estimatedPrice }: { estimatedPrice: IInspectionDetail } = useSelector(
    (state: any) => state.estimatedPrice,
  );
  const user = useSelector((state: any) => state.user.currentUser);

  useEffect(() => {
    if (estimatedPrice) {
      const {
        model,
        no_of_owners,
        state,
        variant,
        registration_number,
        brand,
        manufacturing_year,
        max_km_range,
        min_km_range,
        max_price,
        min_price,
      } = estimatedPrice;

      const trackProperties = {
        ...(brand && { vehicle_brand: brand }),
        ...(state && { state }),
        ...(manufacturing_year && { year: manufacturing_year }),
        ...(model && { model }),
        ...(variant && { variant }),
        ...(no_of_owners && { no_of_owners }),
        ...(registration_number && { registration_number }),
        ...(min_km_range &&
          max_km_range && {
            kms_driven: `${min_km_range}KM - ${max_km_range}KM`,
          }),
        ...(min_price && max_price
          ? { estimate_available: "Yes" }
          : { estimate_available: "No" }),
        name: user?.name,
        phone_number: user?.phoneNumber,
      };

      track(PRICE_ESTIMATE_VIEWED, trackProperties);
    }
  }, []);

  return (
    <section className="flex flex-col md:flex-row gap-4 md:min-h-[600px] max-w-7xl mx-auto w-full justify-between items-center">
      <div className="flex flex-col flex-1 gap-6 md:gap-10">
        <>
          <p className="text-white text-center text-2xl md:text-4xl md:text-start md:leading-[52px] font-semibold">
            Here’s the <span className="text-primaryA2"> best offer </span>for{" "}
            <br /> your two-wheeler!
          </p>
          <div className="flex justify-center md:justify-start flex-wrap gap-10">
            {sellingSteps.map((step, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row items-center gap-2 md:gap-4"
              >
                <span className="w-12 h-12 bg-accent rounded-full flex-center">
                  {step.icon}
                </span>
                <p className="font-semibold text-sm md:text-lg text-white text-center lg:text-start">
                  {step.step}
                </p>
              </div>
            ))}
          </div>
        </>

        {!isMobile && (
          <div className="flex justify-between text-white text-center max-w-[500px]">
            <AvailabilityData />
          </div>
        )}
      </div>

      <FinalPriceEstimation estimatedPrice={estimatedPrice} />

      {isMobile && (
        <div className="flex justify-between text-white text-center w-full">
          <AvailabilityData />
        </div>
      )}
    </section>
  );
};

export default HeroSection;
