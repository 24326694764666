import BlogCard from "@/components/Blogs/BlogCard";
import Navbar from "@/components/Navbar";
import { useGetAllBlogsQuery } from "@/store/services/blog";
import { useEffect, useState } from "react";
import HeroGIFBanner from "../Static/components/HeroGIFBanner";
import Footer from "@/components/Footer";
import BlogSkeletonLoader from "@/components/SkeletonLoaders/Blogs";

function Blog() {
  const { data, isFetching } = useGetAllBlogsQuery({
    params: {
      "fields[0]": "title",
      "fields[1]": "slug",
      "fields[2]": "createdAt",
      "fields[3]": "updatedAt",
      "fields[4]": "publishedAt",
      "fields[5]": "keywords",
      "populate[0]": "cover",
      "populate[1]": "collection",
    },
  });
  const [blogs, setBlogs] = useState<IBlog[]>([]);

  useEffect(() => {
    if (data && data.data) {
      setBlogs(data.data);
    }
  }, [data]);

  const skeletonLoader = Array.from({ length: 6 }, (_, index) => (
    <BlogSkeletonLoader key={index} />
  ));

  return (
    <section className="bg-neutral30">
      <Navbar showSearchbar={false} backgroundColor="bg-neutral900" />

      <div className="pt-[70px] relative">
        <HeroGIFBanner title="Your shortcut to smart insights & tips" />
      </div>

      <div className="w-full p-4 max-w-7xl mx-auto">
        <h1 className="text-xl font-semibold ">Latest Blogs</h1>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-4">
          {isFetching
            ? skeletonLoader
            : blogs.map((blog: IBlog) => (
                <BlogCard key={blog.id} blog={blog} />
              ))}
        </div>
      </div>

      <div className="flex flex-col gap-6 bg-neutral900 py-4">
        <Footer />
      </div>
    </section>
  );
}

export default Blog;
