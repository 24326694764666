export function checkAllValuesWithType(
  obj: { [key: string]: any },
  type = undefined,
): boolean {
  return Object.values(obj).every((value) => value === type);
}

export function deepEqual(
  obj1: { [x: string]: any } | null,
  obj2: { [x: string]: any } | null,
) {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 != "object" ||
    typeof obj2 != "object" ||
    obj1 == null ||
    obj2 == null
  )
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export const isArrayOfStrings = (data: any[]) => {
  return Array.isArray(data) && data.some((item) => typeof item === "string");
};
