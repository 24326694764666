export function generateMetaInfo({
  path,
  city = "",
  productDetail = {},
  storeDetail = {},
  blogDetail = {},
}) {
  // Normalize the path by removing any trailing slashes
  const normalizedPath = path.replace(/\/+$/, "");

  switch (normalizedPath) {
    case "/":
      return {
        seoTitle: "Buy, Sell, Service Used Bikes Online | DriveX",
        seoDescription:
          "Choose from a variety of Quality Used Bikes. DriveX Certified evaluated at 100+ checkpoints. Benefit from Simple Financing, RC transfer & Free Home Inspections. Service your Bike at nearest DriveX Service Centre",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: "/",
        keywords:
          "used bikes,second hand bike,buy used bike,sell used bike,second hand bike in bangalore,second hand bike in chennai,second hand bike in coimbatore,second hand bike in delhi,2nd hand bike in bangalore,2nd hand bike in chennai,2nd hand bike in coimbatore,2nd hand bike in delhi,2nd hand bikes",
      };

    case `/${city}/buy-two-wheelers`:
      return getCityBasedSEOData(city);

    case `/${city}/buy-two-wheelers/${productDetail?.vehicleId}`:
      return {
        seoTitle: `${productDetail?.vehicleName}, ${productDetail?.kmDriven?.displayValue} Kms - Pre-Owned Two Wheeler at DriveX`,
        seoDescription: `${productDetail?.vehicleName}, ${productDetail?.kmDriven?.displayValue} Kms, priced at ₹${productDetail?.price?.displayValue} - Pre-Owned Two Wheeler at DriveX`,
        seoImage:
          `${productDetail?.imagePaths[0]}` ??
          "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: `/${city}/buy-two-wheelers/${productDetail?.vehicleId}`,
        keywords: `${productDetail?.vehicleName},${productDetail?.vehicleName} price,${productDetail?.vehicleName} price in ${city},second hand bikes,second hand bikes in ${city},second hand ${productDetail?.vehicleName},2nd hand ${productDetail?.vehicleName},pre owned bikes,pre owned ${productDetail?.vehicleName}`,
      };

    case `/${storeDetail?.city}/${storeDetail?.slug}`:
      return {
        seoTitle: `Used Bikes at ${storeDetail?.store_name}, ${storeDetail?.city} | DriveX`,
        seoDescription: `Explore DriveX certified two-wheelers in ${storeDetail?.city} at ${storeDetail?.store_name}. Avail 1 Year Warranty, 3 Free Services, Easy Documentation`,
        seoImage:
          storeDetail?.image_url ??
          "https://ik.imagekit.io/drivex/og_store_front.jpg",
        canonicalUrl: `/${storeDetail?.city}/${storeDetail?.slug}`,
        keywords: `used bikes at ${storeDetail?.store_name}, ${storeDetail?.city},second hand bikes,second hand bikes in ${storeDetail?.city},second hand bike showroom near me,second hand bike showroom in ${storeDetail?.city},second hand bikes near me`,
      };

    case "/sell-two-wheelers":
      return {
        seoTitle:
          "Sell Your Bike with DriveX | Get Best Price and Enjoy Free RC Transfer",
        seoDescription:
          "Sell your Bike at Best Price with DriveX. Enjoy Free Doorstep Inspection, Hassle Free Documentation. Get Secure & Instant Payment",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: "/sell-two-wheelers",
        keywords:
          "second hand bike price calculator,sell bike online,sell used bikes,sell bike,sell used bike,sell bike online,sell bike,sell my bike,sell bike in 30 minutes,sell your bike,sell 2 wheeler online,sell scooter online,sell two wheeler online,sell my bike online,sell two wheeler,sell used bike,sale my bike,sell 2 wheeler,sell bike instantly,sell scooty online,how to sell my bike,sell bike in delhi,sell old scooter,sell scooter,sell activa online,sell your bike online,sale old bike online,sell bike online chennai,sell bike online instantly,sell motorcycle,sell my scooter,sell old bike online,sell used bike online,2 wheeler sell,sell bike online india,sell motorbike online,sell motorcycle online,sell my old bike,how to sell cycle online,how to sell scooter online,where to sell old bikes",
      };

    case "/service":
      return {
        seoTitle:
          "Service Your Bike with DriveX | Two-Wheeler Maintenance & Repair",
        seoDescription:
          "Get top-notch bike repair and maintenance services at DriveX. Our expert team ensures your two-wheeler runs smoothly and efficiently. Find nearest bike service center and book your service today!",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: "/service",
        keywords:
          "Top-notch bike repair,Bike maintenance services,DriveX bike service,Expert bike repair team,Two-wheeler maintenance,Nearest bike service center,Book bike service online,Efficient bike repair,Reliable bike maintenance,Professional bike service,Smooth running bikes,Best bike repair services,Local bike repair center,Quick bike maintenance,Comprehensive bike services",
      };

    case "/blogs":
      return {
        seoTitle: "DriveX Blogs | Latest Automotive Trends and Insights",
        seoDescription:
          "Stay updated with DriveX Blogs. Explore the latest trends, insights, and articles in the automotive world. Join our community of bike enthusiasts.",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: "/blogs",
        keywords:
          "DriveX Blogs,Automotive Trends,Insights,Bike Reviews,Bike Buying Guide,Bike Selling Guide,Bike Buying Advice,Bike Selling Advice",
      };

    case `/blogs/${blogDetail?.attributes?.slug}`:
      return {
        seoTitle: `${blogDetail?.attributes?.seo?.metaTitle ?? blogDetail?.attributes?.title} | DriveX - Blogs`,
        seoDescription:
          blogDetail?.attributes?.seo?.metaDescription ??
          "Discover insightful articles and updates on the latest trends in the automotive world with DriveX Blogs.",
        seoImage: `${blogDetail?.attributes?.cover?.data?.attributes?.url}`,
        canonicalUrl: `/blogs/${blogDetail?.attributes?.slug}`,
        keywords:
          blogDetail?.attributes?.seo?.keywords ??
          blogDetail?.attributes?.title,
      };

    case "/about-us":
      return {
        seoTitle: "About Us | DriveX",
        seoDescription:
          "Buy or sell used bikes at best price from DriveX. Avail 1 Year Warranty, 3 Free Services, Easy Documentation",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: "/about-us",
        keywords: "drivex,drivex about us,drivex mission,drivex vision",
      };

    case "/contactus":
      return {
        seoTitle: "Contact Us | DriveX",
        seoDescription:
          "Want to buy or sell used bikes? Contact DriveX for more information and assistance.",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: "/contactus",
        keywords: "drivex contact us,drivex contact",
      };

    case "/privacy-policy":
      return {
        seoTitle: "Privacy Policy | DriveX",
        seoDescription: "Review DriveX's privacy policy for more information.",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: "/privacy-policy",
        keywords: "drivex privacy policy",
      };

    case "/terms":
      return {
        seoTitle: "Terms & Conditions | DriveX",
        seoDescription:
          "Review DriveX's terms and conditions for more information.",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: "/terms",
        keywords: "drivex terms and conditions",
      };

    default:
      return {
        seoTitle: "Buy and Sell Used Bikes Online | DriveX",
        seoDescription:
          "Choose from a variety of Quality Used Bikes. DriveX Certified evaluated at 100+ checkpoints. Benefit from Simple Financing, RC transfer & Free Home Inspections",
        seoImage: "https://ik.imagekit.io/drivex/og_image_drivex.png",
        canonicalUrl: `${normalizedPath}`,
        keywords:
          "used bikes,second hand bike,buy used bike,sell used bike,second hand bike in bangalore,second hand bike in chennai,second hand bike in coimbatore,second hand bike in delhi,2nd hand bike in bangalore,2nd hand bike in chennai,2nd hand bike in coimbatore,2nd hand bike in delhi,2nd hand bikes",
      };
  }
}

function getCityBasedSEOData(city) {
  switch (city) {
    case "Bangalore":
      return {
        seoTitle: "Quality Used Bikes in Bangalore | Second Hand Bikes",
        seoDescription:
          "Explore Top-Quality Used Bikes in Bangalore at DriveX. Visit Branded Showroom at BTM Layout for Best Deals on Second Hand 2-wheelers. Enjoy 1 Year Warranty, 3 Free Services & Easy EMI Options.",
        seoImage: "https://ik.imagekit.io/drivex/og_image_productlist.jpg",
        canonicalUrl: `/${city}/buy-two-wheelers`,
        keywords: `used bikes in ${city},second hand bikes in ${city},second hand bikes,2nd hand bikes in ${city},second hand bike showroom,second hand bike showroom in ${city},second hand bikes near me, second hand scooty in ${city},used two wheelers in ${city}`,
      };
    case "Chennai":
      return {
        seoTitle: "Buy Used Bikes in Chennai | Second Hand Bikes for Sale",
        seoDescription:
          "Explore DriveX certified two-wheelers in Chennai at DriveX Showroom located at Poonamallee. Avail 1 Year Warranty, 3 Free Services, Easy Documentation",
        seoImage: "https://ik.imagekit.io/drivex/og_image_productlist.jpg",
        canonicalUrl: `/${city}/buy-two-wheelers`,
        keywords: `used bikes in ${city},second hand bikes in ${city},second hand bikes,2nd hand bikes in ${city},second hand bike showroom,second hand bike showroom in ${city},second hand bikes near me, second hand scooty in ${city},used two wheelers in ${city}`,
      };
    case "Coimbatore":
      return {
        seoTitle: "Buy Used Bikes in Coimbatore | Second Hand Two-wheelers",
        seoDescription:
          "Explore a wide range of Quality Used bikes in Coimbatore at DriveX Showroom at Kamaraj Road.  Easy EMI Options. Best Price. Easy RC Transfer",
        seoImage: "https://ik.imagekit.io/drivex/og_image_productlist.jpg",
        canonicalUrl: `/${city}/buy-two-wheelers`,
        keywords: `used bikes in ${city},second hand bikes in ${city},second hand bikes,2nd hand bikes in ${city},second hand bike showroom,second hand bike showroom in ${city},second hand bikes near me, second hand scooty in ${city},used two wheelers in ${city}`,
      };
    default:
      return {
        seoTitle: `Explore Pre-Owned Bikes in ${city} | DriveX`,
        seoDescription: `Browse through our collection of pre-owned bikes in ${city} at DriveX`,
        seoImage: "https://ik.imagekit.io/drivex/og_image_productlist.jpg",
        canonicalUrl: `/${city}/buy-two-wheelers`,
        keywords: `used bikes in ${city},second hand bikes in ${city},second hand bikes,2nd hand bikes in ${city},second hand bike showroom,second hand bike showroom in ${city},second hand bikes near me, second hand scooty in ${city},used two wheelers in ${city}`,
      };
  }
}
