import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NAVBAR_HEIGHT, NUDGE_DELAY } from "@/utils/constants.ts";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils.ts";
import { track } from "@/utils/mixpanel/actions.ts";
import {
  NAVBAR_SEARCH_BAR_EVENT,
  NAVBAR_LOCATION_EVENT,
  NAVBAR_HAMBURGER_MENU_EVENT,
  NAVBAR_LOGO_EVENT,
} from "../../utils/mixpanel/Events/navbar_clicked_events.ts";
import { getPageSource } from "@/utils/mixpanel/pageSource.tsx";
import MobileNavbar from "./MobileNavbar.tsx";
import DesktopNavbar from "./DesktopNavbar.tsx";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import Sidebar from "../Sidebar/index.tsx";

interface IProps {
  backgroundColor?: string;
  showLocation?: boolean;
  showSearchbar?: boolean;
  showLinks?: boolean;
  disableLocation?: boolean;
}

const Navbar = ({
  backgroundColor,
  showLocation = true,
  showSearchbar = true,
  showLinks = true,
  disableLocation = false,
}: IProps) => {
  const { isDesktop } = useScreenDetector();
  const [showNudge, setShowNudge] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [searchParams] = useSearchParams();
  const [openSearch, setOpenSearch] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  let { location } = useSelector((state: any) => state.location);
  location = location?.replaceAll("-", " ");
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const pathURL = useLocation();
  const pageSource = getPageSource(pathURL.pathname);

  useEffect(() => {
    if (searchParams.get("search") === "true") {
      setOpenSearch(true);
    }
  }, [searchParams]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setShowNudge(true);
    }, NUDGE_DELAY);

    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const handleAccountClick = () => {
    setOpenSidebar(true);
  };

  const handleSearchbarClick = () => {
    setOpenSearch(true);
    track(NAVBAR_SEARCH_BAR_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    if (pathURL.search) {
      navigate(`${pathURL.search}&search=true`);
    } else {
      navigate("?search=true");
    }
  };

  const handleSelectCity = () => {
    setShowNudge(false);
    setOpenLocation(true);
    track(NAVBAR_LOCATION_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  const handleHamburgerClick = () => {
    track(NAVBAR_HAMBURGER_MENU_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    setOpenSidebar(true);
  };

  const handleLogoClick = () => {
    track(NAVBAR_LOGO_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    navigate("/");
  };

  const renderNavbar = () => {
    if (isDesktop) {
      return (
        <DesktopNavbar
          backgroundColor={backgroundColor as string}
          handleLogoClick={handleLogoClick}
          handleSearchbarClick={handleSearchbarClick}
          handleSelectCity={handleSelectCity}
          location={location}
          openLocation={openLocation}
          setOpenLocation={setOpenLocation}
          setShowNudge={setShowNudge}
          showNudge={showNudge}
          showLinks={showLinks}
          showLocation={showLocation}
          showSearchbar={showSearchbar}
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          user={user}
          handleAccountClick={handleAccountClick}
          disableLocation={disableLocation}
        />
      );
    }
    return (
      <MobileNavbar
        backgroundColor={backgroundColor as string}
        handleHamburgerClick={handleHamburgerClick}
        handleLogoClick={handleLogoClick}
        handleSearchbarClick={handleSearchbarClick}
        handleSelectCity={handleSelectCity}
        location={location}
        openLocation={openLocation}
        setOpenLocation={setOpenLocation}
        setShowNudge={setShowNudge}
        showNudge={showNudge}
        showLocation={showLocation}
        showSearchbar={showSearchbar}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
      />
    );
  };

  return (
    <div
      className={cn(
        `flex flex-col px-4 py-4 fixed w-full z-10 navbar transition duration-300 ease max-h-[${NAVBAR_HEIGHT}px] z-20`,
        backgroundColor,
      )}
    >
      <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      {renderNavbar()}
    </div>
  );
};

export default Navbar;
