import { X as CloseIcon } from "lucide-react";
import Otp from "./Otp";
import { useEffect, useState } from "react";
import {
  createCode,
  doesPhoneNumberExist,
} from "supertokens-web-js/recipe/passwordless";
import LoginOrSignup from "./LoginOrSignup";
import { isValidPhoneNumber } from "@/utils/validation";
import { useToast } from "../../components/ui/use-toast";
import { useSearchParams } from "react-router-dom";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { useSelector } from "react-redux";
import { track } from "@/utils/mixpanel/actions";
import {
  LOGIN_BOTTOMSHEET_OPENED,
  LOGIN_PH_NO_ENTERED,
  LOGIN_USER_NAME_ENTERED,
  OTP_SENT_SUCCESSFULLY,
} from "@/utils/mixpanel/Events/auth_clicked_events";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { usePendingAction } from "@/hooks/usePendingAction";
import { DriveXLogo } from "@/assets/Icons";

const Auth = () => {
  const [userDetails, setUserDetails] = useState({
    phoneNumber: "",
    name: "",
  });
  const [isSignUpView, setIsSignupView] = useState(false);
  const [isOtpView, setIsOtpView] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { toast } = useToast();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handlePendingAction } = usePendingAction();
  const user = useSelector((state: any) => state.user.currentUser);
  const login = searchParams.get("login");
  const { isMobile } = useScreenDetector();

  useEffect(() => {
    if (login === "true") {
      track(LOGIN_BOTTOMSHEET_OPENED, {});
    }
  }, [login]);

  if (user) return;

  async function sendOTP() {
    try {
      const response = await createCode({
        phoneNumber: `+91${userDetails.phoneNumber}`,
      });

      if (response.status === "OK") {
        track(OTP_SENT_SUCCESSFULLY, {
          phone_number: userDetails.phoneNumber,
        });
        setIsOtpView(true);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        if (err.message === "Phone number is invalid")
          setErrorMsg("Invalid number");
      } else {
        toast({
          title: err.message,
          variant: "destructive",
        });
      }
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMsg.length > 0) setErrorMsg("");

    const { name, value } = e.target;

    if (name === "phoneNumber" && value.length > 10) return;

    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleButtonDisable = () => {
    if (!isSignUpView && userDetails.phoneNumber.length < 10) {
      return true;
    }

    if (
      isSignUpView &&
      (userDetails.phoneNumber === "" || userDetails.name === "")
    ) {
      return true;
    }

    return false;
  };

  const handleProceed = async () => {
    track(
      userDetails.name === "" ? LOGIN_PH_NO_ENTERED : LOGIN_USER_NAME_ENTERED,
      {
        name: userDetails.name === "" ? undefined : userDetails.name,
        phone_number: userDetails.phoneNumber,
      },
    );

    if (!isValidPhoneNumber(userDetails.phoneNumber)) {
      setErrorMsg("Invalid number");
      return;
    } else {
      setErrorMsg("");
    }

    if (isSignUpView) {
      sendOTP();
      return;
    }

    const response = await doesPhoneNumberExist({
      phoneNumber: `+91${userDetails.phoneNumber}`,
    });

    if (response.doesExist) {
      sendOTP();
    } else {
      setIsSignupView(true);
    }
  };

  const headerText = () => {
    if (isOtpView) {
      return "OTP Verification";
    } else if (isSignUpView) {
      return "Welcome to";
    }
    return "Login to";
  };

  const handleAuthChange = () => {
    if (!user) {
      handlePendingAction({ actionType: "CLEAR_PENDING_ACTION" });
    }

    const redirect = searchParams.get("redirect");
    searchParams.delete("login");
    if (redirect) searchParams.delete("redirect");
    setSearchParams(searchParams, {
      preventScrollReset: true,
      replace: true,
    });
  };

  return (
    <Sheet
      open={login === "true" ? true : false}
      onOpenChange={handleAuthChange}
    >
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="flex flex-col gap-4 p-4 pt-6 rounded-t-lg"
      >
        <SheetHeader className="mb-6 mt-2">
          <div className="flex justify-between items-center">
            <div className="flex-center gap-3">
              <p className="text-xl">{headerText()} </p>
              {!isOtpView && <DriveXLogo size={92} />}
            </div>

            <SheetClose>
              <CloseIcon size={20} />
            </SheetClose>
          </div>
        </SheetHeader>

        {isOtpView ? (
          <Otp
            userDetails={userDetails}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            setIsOtpView={setIsOtpView}
            otp={otp}
            setOtp={setOtp}
            searchParams={searchParams}
            setUserDetails={setUserDetails}
          />
        ) : (
          <LoginOrSignup
            isSignUpView={isSignUpView}
            userDetails={userDetails}
            handleChange={handleChange}
            handleButtonDisable={handleButtonDisable}
            handleProceed={handleProceed}
            errorMsg={errorMsg}
          />
        )}
      </SheetContent>
    </Sheet>
  );
};

export default Auth;
