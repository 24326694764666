import { FAQsDataType } from ".";

export const homeFAQsData: FAQsDataType["content"] = [
  {
    heading: "Why should I buy a used bike from DriveX?",
    description:
      "DriveX-certified bikes not only look, feel and ride like new; they undergo rigorous 100+ quality checkpoints to ensure superior quality. In addition to this assurance, DriveX offers a comprehensive package including a 1-year warranty, 3 free services, hassle-free RC transfer, 1 year comprehensive insurance, easy financing options, and more.",
  },
  {
    heading: "How can I buy a bike from DriveX?",
    description:
      "To buy a two-wheeler, book a test ride, explore the vehicle virtually via WhatsApp tours, or contact our stores for details. Once you’re happy with your choice, complete your purchase at the DriveX showroom.",
  },
  {
    heading: "Can I exchange my bike/scooter in DriveX?",
    description:
      "Yes, you can. Sell your old two-wheeler in DriveX and take home a certified two-wheeler refurbished by experts. DriveX makes the entire process easy and hassle-free for you.",
  },
  {
    heading: "Does DriveX provide a warranty for two-wheelers?",
    description:
      "Yes, we do. At DriveX, you can get a warranty of up to 1 year for any two-wheeler you buy. You also get 3 free services as well.",
  },
  {
    heading: "How long does it take for RC transfer?",
    description:
      "DriveX ensures that you experience an easy and hassle-free experience. A complete transfer of ownership will take up to 90 days.",
  },
  {
    heading: "In which cities does DriveX have showrooms?",
    description:
      "DriveX has both company-owned, as well as franchise-owned stores that are spread across 40+ cities in India.",
  },
];

export const sellFAQsData: FAQsDataType["content"] = [
  {
    heading: "How can I sell my two-wheeler in DriveX?",
    description:
      "You can sell your bike online with DriveX, providing accurate bike details followed by a free doorstep inspection.",
  },
  {
    heading: "What documents are needed to sell my bike to DriveX?",
    description:
      "You will need the documents to prove the undisputed ownership of the two-wheeler such as the RC, and a copy of the existing insurance policy.",
  },
  {
    heading: "Can I exchange my old bike for a new one in DriveX?",
    description:
      "You can sell your current bike in DriveX, and buy a certified refurbished two-wheeler.",
  },
  {
    heading:
      "Will I be liable for any issues regarding my two-wheeler after selling it to DriveX?",
    description:
      "No. Once you sell your vehicle to DriveX, you will not be liable for any issues regarding your vehicle.",
  },
  {
    heading: "What benefits do I get if I sell to DriveX?",
    description:
      "DriveX guarantees the best value for your two-wheeler, hassle-free transfer of ownership, and a stress-free selling experience.",
  },
  {
    heading:
      "What is the mode of payment in DriveX when I sell my two-wheeler?",
    description:
      "You will receive instant payment directly transferred to your bank account while you sell in DriveX.",
  },
  {
    heading:
      "Will I be charged any amount while selling my two-wheeler to DriveX?",
    description:
      "No, you will not be charged anything while selling your two-wheeler to DriveX.",
  },
  {
    heading: "Will I have to handle RC transfer in DriveX?",
    description:
      "At DriveX, we will manage the end-to-end RC transfer process for you.",
  },
];

export const testRideFAQsData: FAQsDataType["content"] = [
  {
    heading: "What are the benefits of buying a vehicle with DriveX?",
    description:
      "You can have peace of mind with a 1-year warranty, 3 free services. Our vehicles are refurbished using quality parts, and undergo rigorous 300+ checkpoints, ensuring a reliable and satisfying ride for you",
  },
  {
    heading: "How can I buy a two-wheeler on DriveX?",
    description:
      "To buy a two-wheeler, simply book a test drive, explore the vehicle virtually via WhatsApp tours, or contact our dealer for details. Once you’re happy with your choice, complete your purchase at the DriveX showroom",
  },
  {
    heading: "What are the documents required during vehicle purchase?",
    description:
      "Following documents are required at the time of the vehicle delivery: a) Aadhaar card copy b) PAN card copy c) Address proof. In case of any additional documents required, the respective dealer will contact you",
  },
];

export const wishlistFAQsData: FAQsDataType["content"] = [
  {
    heading: "How can I buy a two-wheeler on DriveX?",
    description:
      "To buy a two-wheeler, simply book a test drive, explore the vehicle virtually via WhatsApp tours, or contact our dealer for details. Once you’re happy with your choice, complete your purchase at the DriveX showroom.",
  },
  {
    heading: "What are the benefits of buying a vehicle with DriveX?",
    description:
      "You can have peace of mind with a 1-year warranty, 3 free services. Our vehicles are refurbished using quality parts, and undergo rigorous 300+ checkpoints, ensuring a reliable and satisfying ride for you.",
  },
];

export const serviceFAQsData: FAQsDataType["content"] = [
  {
    heading: "Does DriveX have doorstep service?",
    description:
      "No, we do not have a doorstep service, however, we do have a free pick-up and drop-off within a 3 km radius of the service centers.",
  },
  {
    heading: "How can I book a service in DriveX?",
    description:
      "You can book a service through the website, or call your nearest DriveX store to book an appointment.",
  },
];
