import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import { BOTTOM_NAVIGATION_HEIGHT } from "@/utils/constants";
import { maxCountDisplay, trackBottomNavigation } from "./data";
import { useLocation } from "react-router-dom";

export interface IBottomNavigation {
  tabs: {
    icon: (focused: boolean) => JSX.Element;
    text: string;
    action: string;
    count?: number;
  }[];
  handleClick: (ar1: string) => void;
  backgroundColor?: string;
}

const BottomNavigation = ({
  tabs,
  handleClick,
  backgroundColor,
}: IBottomNavigation) => {
  const { isMobile } = useScreenDetector();
  const location = useLocation();

  return (
    <>
      {isMobile && (
        <div
          id="bottom-navigation"
          className={cn(
            `flex w-screen justify-around py-2 fixed bg-white bottom-0 transition duration-300 ease`,
            backgroundColor,
          )}
          style={{
            height: BOTTOM_NAVIGATION_HEIGHT,
          }}
        >
          {tabs.map((tab, index) => {
            const isActive = location.pathname === tab.action; // Check if the current location matches the tab action
            return (
              <div
                data-test-id={`bottom-navigation-${tab.text.toLowerCase()}`}
                key={index}
                className={cn(
                  "flex justify-center items-center gap w-full h-full mx-2",
                  isActive && "bg-primaryA2 rounded-md",
                )}
                onClick={() => {
                  handleClick(tab.action);
                  trackBottomNavigation({ tab });
                }}
              >
                {tab.icon(isActive)}
                <p
                  className={cn(
                    "text-sm font-medium px-1.5",
                    isActive
                      ? "text-white"
                      : backgroundColor === "bg-neutral900" && "text-white",
                  )}
                >
                  {tab.text}
                </p>
                {tab.count && !isActive && (
                  <span className="min-w-[16px] min-h-[16px] text-white text-center font-medium text-xs bg-primaryA1 rounded-full px-1">
                    {tab.count > maxCountDisplay
                      ? `${maxCountDisplay}+`
                      : tab.count}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default BottomNavigation;
