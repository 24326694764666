import { cn } from "@/lib/utils";
import { emiPlan } from "./data";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import Image from "@/components/ui/image";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";

const EMIImage = `${IMAGE_KIT_BASE_URL}/ik_homepage/REBullet.png`;
interface IProps {
  className?: string;
}

const EmiPlan = ({ className }: IProps) => {
  const { isDesktop } = useScreenDetector();
  return (
    <div
      className={cn(
        "flex gap-6 bg-white p-4 rounded-lg max-w-screen-lg max-h-[23rem] mx-auto w-full overflow-hidden",
        className,
        isDesktop && "p-12",
      )}
    >
      <div className="flex flex-col">
        <p className="text-xl md:text-[28px] md:leading-10 font-semibold">
          <span className="text-primaryA2">Afford</span> your dream <br /> with
          DriveX Finance
        </p>

        <div className="flex gap-2 my-4">
          {emiPlan.map((emi, index) => (
            <div
              key={index}
              className={`flex p-3 rounded-md border ${emi.background_color}`}
            >
              <Image
                transform="f-webp"
                className="w-[4rem]"
                src={emi.img as string}
                alt=""
              />
            </div>
          ))}
        </div>

        <p className="text-neutral300 md:max-w-[340px]">
          Finance for DriveX-certified two-wheelers made easy through DriveX
          Finance, with the backing of industry&apos;s leading finance partners
        </p>
      </div>

      {isDesktop && (
        <Image
          transform="f-webp"
          className="w-[40rem] h-[345px] object-cover object-bottom"
          src={EMIImage}
          alt="emi_static_background"
        />
      )}
    </div>
  );
};

export default EmiPlan;
