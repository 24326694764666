import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
} from "@/components/ui/dialog";
import { ChevronLeft as LeftIcon } from "lucide-react";
import TestRide from "./TestRide";
import { handleShare } from "@/utils/share";
import { useState } from "react";
import ImageViewer from "./ImageViewer";
import { PDP_SHARE_VEHICLE_CLICKED_EVENT } from "@/utils/mixpanel/Events/pdp_clicked_events";
import { track } from "@/utils/mixpanel/actions";
import { trackImageViewerOpened } from "./data";
import { Heart, HeartFilled, ShareIcon } from "@/assets/Icons";
import SoldOut from "./SoldOut";
import { cn } from "@/lib/utils";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import Image from "@/components/ui/image";
import { useSelector } from "react-redux";

interface Iprops {
  isVehicleAvailable: boolean;
  productDetail: IVehicleInfo;
  testRideDetails: {
    date: string;
    exists: boolean;
  } | null;
  whatsappTourExists: {
    exists: boolean;
  } | null;
  setOpenCarouselImgPreview: React.Dispatch<React.SetStateAction<boolean>>;
  handleWishListClick: () => void;
  wishList: number[];
  fetchUpcomingWhatsappTour: () => void;
}

const CarouselImagePreview = ({
  isVehicleAvailable,
  productDetail,
  setOpenCarouselImgPreview,
  handleWishListClick,
  wishList,
  testRideDetails,
  whatsappTourExists,
  fetchUpcomingWhatsappTour,
}: Iprops) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isMobile } = useScreenDetector();
  return (
    <>
      {productDetail && (
        <DialogContent
          className={cn(
            "max-w-full flex flex-col h-dvh p-2.5",
            !isVehicleAvailable && isMobile && "pb-[80px]",
          )}
        >
          <DialogHeader className="bg-white border-neutral50 border-b absolute top-0 right-0 left-0 w-full py-2 px-3 z-10 max-w-7xl mx-auto">
            <div className="flex justify-between items-center text-sm">
              <div className="flex gap-4">
                <DialogClose
                  onClick={() => setOpenCarouselImgPreview(false)}
                  className="focus:outline-none"
                >
                  <LeftIcon />
                </DialogClose>

                <div className="flex flex-col gap-1 items-start">
                  <p>{productDetail.vehicleName}</p>
                  <div className="flex">
                    <p className="text-orange-500">
                      {/* TODO: Remove hardcoded value */}₹
                      {productDetail?.price?.displayValue}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex gap-3">
                <span
                  onClick={() => {
                    handleShare({ vehicleName: productDetail.vehicleName });
                    track(PDP_SHARE_VEHICLE_CLICKED_EVENT, {
                      vehicle_brand: productDetail.brand,
                      vehicle_model: productDetail.modelName,
                      store_location: productDetail.storeMapLink,
                      vehicle_id: productDetail.vehicleId,
                      name: user?.userName,
                      phone_number: user?.phoneNumber,
                      page: "Image Gallery",
                    });
                  }}
                >
                  <ShareIcon />
                </span>
                {isVehicleAvailable && (
                  <span onClick={() => handleWishListClick()}>
                    {productDetail &&
                    wishList &&
                    wishList.includes(productDetail?.vehicleId) ? (
                      <HeartFilled size={18} />
                    ) : (
                      <Heart size={18} />
                    )}
                  </span>
                )}
              </div>
            </div>
          </DialogHeader>

          <div className="max-w-7xl mx-auto w-full overflow-x-scroll no-scrollbar py-[56px]">
            {productDetail.imagePaths && (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                {productDetail.imagePaths.map((imgItem, index) => (
                  <Image
                    transform="q-10,fo-motorcycle"
                    key={index}
                    src={imgItem as string}
                    alt={`${productDetail.vehicleName} ${productDetail.modelName}`}
                    onClick={() => {
                      setCurrentImageIndex(index);
                      setOpenImageViewer(true);
                      trackImageViewerOpened({
                        productDetail,
                        currentImageIndex: index,
                      });
                    }}
                    className="rounded-md h-52 w-full object-cover"
                  />
                ))}
                {openImageViewer && (
                  <Dialog open={openImageViewer}>
                    <ImageViewer
                      productDetail={productDetail}
                      imagePaths={productDetail.imagePaths}
                      setOpenImageViewer={setOpenImageViewer}
                      activeImage={currentImageIndex}
                    />
                  </Dialog>
                )}
              </div>
            )}

            <div
              className={cn(
                "absolute bottom-0 right-0 left-0 max-w-7xl mx-auto w-full p-2 bg-white",
                !isVehicleAvailable && "max-w-2xl",
              )}
            >
              {isVehicleAvailable && (
                <TestRide
                  testRideDetails={testRideDetails}
                  vehicleDetails={productDetail}
                  whatsappTourExists={whatsappTourExists}
                  fetchUpcomingWhatsappTour={fetchUpcomingWhatsappTour}
                />
              )}

              {!isVehicleAvailable && <SoldOut />}
            </div>
          </div>
        </DialogContent>
      )}
    </>
  );
};

export default CarouselImagePreview;
