import Location from "../Location/index.tsx";
import Nudge from "./Nudge.tsx";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "../ui/button.tsx";
import { Input } from "../ui/input.tsx";
import { cn } from "@/lib/utils.ts";
import {
  DownArrowIcon,
  DriveXLogo,
  HamburgerIcon,
  SearchIcon,
} from "@/assets/Icons.tsx";
import AnimatedLabel from "../AnimatedLabel/index.tsx";
import {
  PLACEHOLDER_TEXTS,
  PLACEHOLDER_TEXT_INTERVAL,
} from "@/utils/constants.ts";
import Search from "@/pages/Search/index.tsx";

interface IProps {
  handleHamburgerClick: () => void;
  handleLogoClick: () => void;
  handleSelectCity: () => void;
  handleSearchbarClick: () => void;
  openLocation: boolean;
  setOpenLocation: React.Dispatch<React.SetStateAction<boolean>>;
  openSearch: boolean;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  showNudge: boolean;
  setShowNudge: React.Dispatch<React.SetStateAction<boolean>>;
  backgroundColor: string;
  location: any;
  showLocation?: boolean;
  showSearchbar?: boolean;
}

const MobileNavbar = ({
  handleHamburgerClick,
  handleLogoClick,
  handleSearchbarClick,
  handleSelectCity,
  setOpenLocation,
  setOpenSearch,
  setShowNudge,
  backgroundColor,
  openLocation,
  openSearch,
  location,
  showNudge,
  showLocation,
  showSearchbar,
}: IProps) => {
  return (
    <header className="">
      <div className={cn("flex-between", showSearchbar && "pb-4")}>
        <div className="flex gap-4 flex-row items-center">
          <span
            className="cursor-pointer"
            onClick={handleHamburgerClick}
            data-test-id="sidebar-button"
          >
            <HamburgerIcon />
          </span>
          <span
            data-test-id="navbar-drivex-logo"
            className="cursor-pointer"
            onClick={handleLogoClick}
          >
            <DriveXLogo size={130} />
          </span>
        </div>

        {showLocation && (
          <div className="flex flex-col relative">
            <Dialog open={openLocation}>
              <DialogTrigger asChild>
                <Button
                  data-test-id="select-city"
                  onClick={handleSelectCity}
                  variant="outline"
                  className={cn(
                    "flex gap-3 border-none",
                    backgroundColor === "bg-neutral900"
                      ? "bg-neutral700"
                      : "bg-neutral30",
                  )}
                >
                  <span className="text-primaryA2">
                    {location || "Select city"}
                  </span>
                  <DownArrowIcon />
                </Button>
              </DialogTrigger>

              <Location setOpenLocation={setOpenLocation} />
            </Dialog>

            {showNudge && (
              <div className="absolute top-[50px] z-10 right-[-5px] w-72">
                <Nudge
                  setOpenLocation={setOpenLocation}
                  setShowNudge={setShowNudge}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {openSearch && <Search setOpenSearch={setOpenSearch} />}

      {showSearchbar && (
        <div
          data-test-id="Search-icon-input"
          onClick={handleSearchbarClick}
          className={cn(
            "flex items-center px-4 rounded-md bg-neutral30 border-none relative",
          )}
        >
          <SearchIcon />

          <AnimatedLabel
            texts={PLACEHOLDER_TEXTS}
            interval={PLACEHOLDER_TEXT_INTERVAL}
            label="Search for"
          />

          <Input className={"bg-neutral30 border-none"} />
        </div>
      )}
    </header>
  );
};

export default MobileNavbar;
