import Location from "../Location/index.tsx";
import Nudge from "./Nudge.tsx";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "../ui/button.tsx";
import { Input } from "../ui/input.tsx";
import { cn } from "@/lib/utils.ts";
import {
  AvatarIcon,
  DownArrowIcon,
  DriveXLogo,
  SearchIcon,
} from "@/assets/Icons.tsx";
import { Link } from "react-router-dom";
import Search from "@/pages/Search/index.tsx";
import { HomeTabs } from "../BottomNavigation/data.tsx";
import { Avatar } from "../ui/avatar.tsx";

interface IProps {
  handleLogoClick: () => void;
  handleSelectCity: () => void;
  handleSearchbarClick: () => void;
  openLocation: boolean;
  setOpenLocation: React.Dispatch<React.SetStateAction<boolean>>;
  openSearch: boolean;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
  showNudge: boolean;
  setShowNudge: React.Dispatch<React.SetStateAction<boolean>>;
  backgroundColor: string;
  location: any;
  showLocation?: boolean;
  showSearchbar?: boolean;
  showLinks?: boolean;
  handleAccountClick: () => void;
  user: { userName: string };
  disableLocation: boolean;
}

const DesktopNavbar = ({
  handleLogoClick,
  handleSearchbarClick,
  handleSelectCity,
  setOpenLocation,
  setOpenSearch,
  setShowNudge,
  handleAccountClick,
  backgroundColor,
  openLocation,
  openSearch,
  location,
  showNudge,
  showLocation,
  showSearchbar,
  showLinks,
  user,
  disableLocation,
}: IProps) => {
  // Fetching home tabs data from the HomeTabs function
  const { homeTabsData } = HomeTabs();
  // Destructuring the tabs into Buy, Wishlist, and Sell tabs
  const [BuyTab, ServiceTab, WishlistTab, SellTab] = homeTabsData;
  // Organizing the navigation bar links in the desired order
  const navBarLinksData = [BuyTab, SellTab, ServiceTab, WishlistTab];

  return (
    <header className="max-w-7xl mx-auto w-full flex justify-between">
      <div className="flex gap-6">
        <span
          data-test-id="navbar-drivex-logo"
          className="cursor-pointer"
          onClick={handleLogoClick}
        >
          <DriveXLogo size={130} />
        </span>

        {showLocation && (
          <div className="flex flex-col relative">
            <Dialog open={openLocation}>
              <DialogTrigger asChild>
                <Button
                  data-test-id="select-city"
                  onClick={handleSelectCity}
                  variant="outline"
                  className={cn(
                    "flex gap-3 border-none",
                    backgroundColor === "bg-neutral900"
                      ? "bg-neutral700"
                      : "bg-neutral30",
                  )}
                  disabled={disableLocation}
                >
                  <span className="text-primaryA2">
                    {location || "Select city"}
                  </span>
                  {!disableLocation && <DownArrowIcon />}
                </Button>
              </DialogTrigger>

              <Location setOpenLocation={setOpenLocation} />
            </Dialog>

            {showNudge && (
              <div className="absolute top-[50px] z-10 left-[-5px] w-72">
                <Nudge
                  setOpenLocation={setOpenLocation}
                  setShowNudge={setShowNudge}
                />
              </div>
            )}
          </div>
        )}

        {openSearch && <Search setOpenSearch={setOpenSearch} />}

        {showSearchbar && (
          <div
            data-test-id="nav-searchbar"
            className={cn(
              "flex items-center px-4 rounded-md bg-neutral30 border-none w-72",
            )}
          >
            <SearchIcon />
            <Input
              data-test-id="Search-icon-input"
              onClick={handleSearchbarClick}
              placeholder="Search by brand or model"
              className={"bg-neutral30 border-none"}
            />
          </div>
        )}
      </div>
      <div
        className={cn(
          "flex w-[45%] justify-between",
          !showLinks && "justify-end",
        )}
      >
        {showLinks && (
          <div className="flex items-center w-[66%] justify-between">
            {navBarLinksData.map((data, index) => {
              return (
                <Link to={data?.action ? data?.action : ""} key={index}>
                  <div className="flex justify-center items-center cursor-pointer gap-2">
                    {data?.icon(false)}
                    <p
                      className={cn(
                        "text-base",
                        backgroundColor === "bg-neutral900"
                          ? "text-white"
                          : "text-black",
                      )}
                    >
                      {data?.text}
                    </p>
                    {data?.count && (
                      <span className="w-[16px] h-[16px] text-white text-center font-medium text-xs bg-primaryA1 rounded-full">
                        {data?.count}
                      </span>
                    )}
                  </div>
                </Link>
              );
            })}
          </div>
        )}

        <div
          data-test-id="sidebar-button"
          className="flex items-center gap-3 border border-[#2F2F2F] rounded-md px-2.5 cursor-pointer"
          onClick={handleAccountClick}
        >
          <Avatar className="p-1">
            {user ? (
              <p className="w-full h-full flex justify-center items-center bg-gray-200 rounded-full">
                {user?.userName
                  .split(" ")
                  .map((name: string) => name.charAt(0))}
              </p>
            ) : (
              <span className="w-full h-full flex justify-center items-center">
                <AvatarIcon />
              </span>
            )}
          </Avatar>
          <p
            className={cn(
              "text-white text-sm",
              backgroundColor === "bg-neutral900" ? "text-white" : "text-black",
            )}
          >
            Account
          </p>
          <DownArrowIcon
            fill={backgroundColor === "bg-neutral900" ? "white" : "black"}
          />
        </div>
      </div>
    </header>
  );
};

export default DesktopNavbar;
