import {
  BikeIcon,
  CareersIcon,
  ColouredCalendar,
  DriveXIcon,
  FinanceIcon,
  HeadphoneIcon,
  Heart,
  KeyIcon,
  NoteIcon,
  NotepadIcon,
  WrenchIcon,
} from "@/assets/Icons";
import { BookKeyIcon } from "lucide-react";
import { CAREERS_URL } from "@/utils/constants";

interface userDataType {
  icon: any;
  text: string;
  value: string;
}

export const userData: userDataType[] = [
  { icon: <ColouredCalendar />, text: "Booking", value: "buy" },
  { icon: <NotepadIcon />, text: "Sell Requests", value: "sell" },
  { icon: <Heart />, text: "Wishlist", value: "wishlist" },
  { icon: <HeadphoneIcon />, text: "Help & Support", value: "contactUs" },
];

export interface moreOptionsType {
  icon: any;
  text: string;
  link: string;
}

export const moreOptions: moreOptionsType[] = [
  // { icon: <FinanceIcon />, text: "DriveX Finance" },
  {
    icon: <NoteIcon />,
    text: "Blogs",
    link: "/blogs",
  },
  {
    icon: <BookKeyIcon color="#919191" size={16} />,
    text: "Privacy Policy",
    link: "/privacy-policy",
  },
  {
    icon: <FinanceIcon />,
    text: "Terms of Service",
    link: "/terms",
  },
  { icon: <DriveXIcon />, text: "About DriveX", link: "/about-us" },
  { icon: <CareersIcon />, text: "Careers", link: CAREERS_URL },
];

interface BuySellType {
  icon: React.ReactNode;
  heading: string;
  text: string;
}

export const BuySellCTA: BuySellType[] = [
  {
    icon: (
      <WrenchIcon
        size={14}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="sidebar_paint_wrench"
      />
    ),
    heading: "Service",
    text: "Take your bike for a spa",
  },
  {
    icon: (
      <BikeIcon
        size={16}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="sidebar_paint_bike"
      />
    ),
    heading: "Buy",
    text: "Quality two-wheelers",
  },
  {
    icon: (
      <KeyIcon
        size={16}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="sidebar_paint_key"
      />
    ),
    heading: "Sell",
    text: "Get instant estimates",
  },
];

export function getSidebarClickedButton(text: string) {
  switch (text) {
    case "buy":
      return "/profile";
    case "wishlist":
      return "/wishlist";
    case "contactUs":
      return "/contactus";
  }
}
