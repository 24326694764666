import * as Sentry from "@sentry/react";

const sentryUrl = import.meta.env.VITE_SENTRY_DSN_URL;
const url = import.meta.env.VITE_URL;
const traceUrl = import.meta.env.VITE_TRACE_MAIN_URL;

export const sentryInit = () => {
  Sentry.init({
    dsn: sentryUrl,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
    ],
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [url, traceUrl],
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};
