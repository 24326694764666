import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Button } from "@/components/ui/button";
import Image from "@/components/ui/image";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";

type IProps = {
  qualityRefurbishmentImages: any;
  handleVideoPopup: (video: string) => void;
};

const QualityRefurbishment = ({
  qualityRefurbishmentImages,
  handleVideoPopup,
}: IProps) => {
  const video = "https://www.youtube.com/embed/QByjJU7luCw?autoplay=1&rel=0";

  const QualityRefurbishmentImage = `${IMAGE_KIT_BASE_URL}/ik_aboutus/QualityRefurbishmentCTAImage.png`;
  const QualityRefurbishmentImageDesktop = `${IMAGE_KIT_BASE_URL}/ik_aboutus/QualityRefurbishmentCTAImageDesktop.png`;
  return (
    <>
      <p className="text-white text-center text-2xl font-medium">
        Driven by Passion,{" "}
        <span className="text-primaryA2">Powered by Technology</span>
      </p>

      <div className="flex flex-col gap-8 max-w-4xl m-auto">
        <Carousel>
          <CarouselContent>
            {qualityRefurbishmentImages?.map((item: any, index: number) => (
              <CarouselItem key={index} className="basis-8/12 sm:basis-4/12">
                <Image
                  src={item?.attributes?.Image?.data?.attributes?.url as string}
                  alt="refurbished_vehicles"
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>

        <p className="text-white text-sm font-normal">
          We give nothing short of the best! We have a team of highly-trained
          technicians who refurbish the two-wheelers in a state-of-the-art
          technical center armed with advanced equipment, where every vehicle is
          taken through more than 100 quality checkpoints and made as good as
          new.
        </p>
        <div className="flex justify-between overflow-hidden bg-neutral700 p-4 py-3 sm:py-1 mb-8">
          <div className="flex flex-col justify-center sm:flex-row sm:justify-between sm:items-center gap-5 sm:w-full rounded-lg">
            <p className="text-white">
              100+ inspection points <br /> to ensure highest quality
            </p>
            <Image
              className="hidden sm:block"
              transform="f-webp"
              src={QualityRefurbishmentImageDesktop}
              alt="Refurbished Bike Desktop"
            />
            <Button
              onClick={() => handleVideoPopup(video)}
              variant="outline"
              className="bg-primaryA2 border-none w-[115px] text-white text-xs hover:bg-primaryA2 hover:text-white"
            >
              Watch Video
            </Button>
          </div>
          <Image
            className="object-cover object-left sm:hidden w-[50%]"
            transform="f-webp"
            src={QualityRefurbishmentImage}
            alt="Refurbished Bike"
          />
        </div>
      </div>
    </>
  );
};

export default QualityRefurbishment;
