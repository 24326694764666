import { cn } from "@/lib/utils";
import { Input } from "../ui/input";

interface IProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  parentClassName?: string;
  className?: string;
  type: string;
  icon?: any;
  name: string;
  placeholder?: string;
  maxLength?: number;
  value: string;
}

const InputWithIcon = ({
  handleChange,
  parentClassName,
  className,
  icon = null,
  ...props
}: IProps) => {
  return (
    <div
      className={cn(
        "flex items-center pr-4 rounded-md bg-neutral30 border-none",
        parentClassName,
      )}
    >
      <Input
        className={cn(
          "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 autofill:shadow-neutral30",
          className,
        )}
        onChange={handleChange}
        {...props}
      />
      {icon}
    </div>
  );
};

export default InputWithIcon;
