import Image from "@/components/ui/image";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";
const DrivingChange = () => {
  const { isMobile, isTablet, isDesktop } = useScreenDetector();
  const EmployeesImage = `${IMAGE_KIT_BASE_URL}/ik_aboutus/EmployeesImage.png`;
  const NarainKartikeyan = `${IMAGE_KIT_BASE_URL}/ik_aboutus/NarainKartikeyan.png`;
  const NarainKartikeyanDesktop = `${IMAGE_KIT_BASE_URL}/ik_aboutus/NarainKartikeyanDesktop.png`;

  return (
    <>
      <p className="text-center font-semibold text-2xl">
        <span className="text-primaryA2">Driving</span> change
      </p>

      <Image
        transform="q-50,fo-auto,f-webp"
        className="rounded-lg w-[60rem] h-[16rem] sm:h-[22rem] object-cover object-center"
        src={EmployeesImage}
        alt="Driving change"
      />
      <p className="text-sm font-medium text-center indent-6">
        DriveX is committed to creating a mobility ecosystem built on trust,
        transparency, and convenience. In India, for every new two-wheeler
        vehicle sold, two pre-owned vehicles are sold. The market is projected
        to reach $10 billion by 2025. The customer, however, is limited by
        choices and quality and is often faced with ambiguous pricing. At
        DriveX, we are dedicated to providing our customers with a superior
        experience, which they deserve while buying, selling, and servicing
        their two-wheelers.
      </p>

      <div className="md:flex md:flex-row-reverse bg-neutral900 rounded-lg">
        {isMobile && (
          <Image
            className="w-full bg-cover,f-webp"
            transform="q-90"
            src={NarainKartikeyan}
            alt="Narain Kartikeyan"
          />
        )}
        {(isDesktop || isTablet) && (
          <Image
            transform="q-90,f-webp"
            src={NarainKartikeyanDesktop}
            alt="Narain Kartikeyan Desktop"
          />
        )}
        <div className="flex flex-col md:justify-center gap-4 p-6">
          <p className="text-white text-2xl font-semibold">
            A passion for{" "}
            <span className="text-primaryA2">speed and precision</span>
          </p>
          <p className="text-white text-sm font-normal">
            DriveX was founded by{" "}
            <span className="text-primaryA2">Narain Kartikeyan</span>,
            India&apos;s first Formula 1 driver. With a passion for speed and
            precision, Narain brings his expertise in motorsports and
            engineering to DriveX, infusing the company with a commitment to
            excellence and innovation.
            <br />
            <br />
            His vision for DriveX is not just about selling bikes but about
            revolutionizing the way people think about mobility, making it more
            accessible, sustainable, and enjoyable for everyone.
          </p>
        </div>
      </div>
    </>
  );
};

export default DrivingChange;
