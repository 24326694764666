import Navbar from "@/components/Navbar";
import EmptyWishlist from "./EmptyWishlist";
import FAQs from "@/components/FAQs";
import { wishlistFAQsData } from "@/components/FAQs/data";
import Footer from "@/components/Footer";
import BottomNavigation from "@/components/BottomNavigation";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getWishListedVehicle,
  removeFromWishList,
} from "@/store/services/scheduleApi";
import ProductCard from "@/components/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { setIsWishListed } from "@/store/features/wishListSlice";
import { HomeTabs } from "@/components/BottomNavigation/data";
import ProductCardSkeletonLoader from "@/components/SkeletonLoaders/ProductCardSkeletonLoader";
import { cn } from "@/lib/utils";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { pluralize } from "@/utils/pluralize";
import { track } from "@/utils/mixpanel/actions";
import { WISHLIST_PAGE_VEHICLE_CARD_CLICKED_EVENT } from "@/utils/mixpanel/Events/wishlist_clicked_events";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";

interface Product {
  isAvailability: boolean;
  vehicleId: number;
}

const Wishlist = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { homeTabsData } = HomeTabs();
  const { wishList } = useSelector((state: any) => state.wishList);
  const { isDesktop } = useScreenDetector();
  const { city } = useCurrentCity();
  const [wishlistData, setWishlistData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    const wishListedVehicleInfo = async () => {
      const fetchWishList = await getWishListedVehicle(`compact=false`);
      setWishlistData(fetchWishList?.vehicles);
      dispatch(
        setIsWishListed(
          fetchWishList?.vehicles?.map(
            (vehicle: Product) => vehicle?.vehicleId,
          ),
        ),
      );
      setIsFetching(false);
    };

    wishListedVehicleInfo();
  }, []);

  const handleWishListClick = async (
    e: React.SyntheticEvent,
    product: Product,
  ) => {
    e.stopPropagation();

    if (!product?.isAvailability) return;

    if (wishList.includes(product?.vehicleId)) {
      await removeFromWishList(product?.vehicleId);
    }

    const fetchWishList = await getWishListedVehicle(`compact=false`);
    setWishlistData(fetchWishList?.vehicles);
    dispatch(
      setIsWishListed(
        fetchWishList?.vehicles?.map((vehicle: Product) => vehicle?.vehicleId),
      ),
    );
  };

  const handleBookTestDriveClick = (
    e: React.SyntheticEvent,
    product: {
      vehicleId: number;
      isScheduled: boolean;
    },
    hasPassedScheduleDate: boolean,
  ) => {
    e.stopPropagation();

    if (product?.isScheduled && !hasPassedScheduleDate) {
      navigate("/profile");
      return;
    }

    navigate(`/${city}/buy-two-wheelers/${product?.vehicleId}/test-ride`);
  };

  const handleProductCardClick = (data: IVehicleInfo, index: number) => {
    if (!data?.isAvailability) return;

    navigate(`/${city}/buy-two-wheelers/${data?.vehicleId}`);

    track(WISHLIST_PAGE_VEHICLE_CARD_CLICKED_EVENT, {
      vehicle_brand: data.brand,
      vehicle_model: data.modelName,
      store_location: data.storeName,
      vehicle_id: data.vehicleId,
      source: "Wishlist Page",
      position: index + 1,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: "Wishlist Page",
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };

  const handleBottomNavigationClick = (url: string) => {
    navigate(url);
  };
  return (
    <>
      <Navbar backgroundColor="bg-white" />
      <div className="flex flex-col bg-neutral30 gap-4 pt-[150px]">
        <div className="flex flex-col gap-4">
          {wishlistData?.length > 0 && (
            <p className="font-semibold text-base sm:text-xl sm:text-center px-4">
              <span className="text-primaryA1">
                {wishlistData?.length} wishlisted
              </span>{" "}
              {pluralize(wishlistData.length, "two-wheeler")}
            </p>
          )}
          <div
            className={cn(
              "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 max-w-7xl mx-auto w-full px-4",
              wishList &&
                isDesktop &&
                wishlistData?.length <= 3 &&
                "flex-center",
            )}
          >
            {isFetching ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 max-w-7xl mx-auto w-full pt-2">
                {Array.from({ length: 6 }, (_, index) => (
                  <ProductCardSkeletonLoader key={index} />
                ))}
              </div>
            ) : wishlistData && wishlistData?.length > 0 ? (
              wishlistData.map((product, index) => (
                <div
                  key={index}
                  className={cn(
                    isDesktop && wishlistData?.length <= 3 && "w-[28rem]",
                  )}
                >
                  <ProductCard
                    product={product}
                    index={index}
                    wishList={wishList}
                    handleClick={() => handleProductCardClick(product, index)}
                    wishlistPage={true}
                    handleWishListClick={handleWishListClick}
                    handleBookTestDriveClick={handleBookTestDriveClick}
                  />
                </div>
              ))
            ) : (
              <EmptyWishlist navigate={navigate} />
            )}
          </div>
        </div>

        {/* FAQs */}
        <div className="flex flex-col gap-4 p-4">
          <FAQs content={wishlistFAQsData} handleFAQClick={handleFAQClick} />

          {/* Commented out as the FAQ page has not been created yet. */}
          {/* <Button
            variant="outline"
            className="border w-full border-orange-500 text-primaryA1 text-base bg-neutral30 py-6"
          >
            Visit FAQ
          </Button> */}
        </div>

        {/* Footer */}
        <div className="bg-neutral900 py-4">
          <Footer />
        </div>

        {/* Bottom Navigation */}
        <BottomNavigation
          handleClick={handleBottomNavigationClick}
          tabs={homeTabsData}
        />
      </div>
    </>
  );
};

export default Wishlist;
